import {Button, Tooltip} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const ButtonDraft = ({
  className = '',
  onClick = () => {},
  hasTooltip = false,
  text = <IntlMessages id='ui-general-action-storage' />,
  tooltipText = <IntlMessages id="feeds-modal-unpublish-image-in-prod" />
}) => {
  return hasTooltip ? (
      <Tooltip placement="topLeft" title={tooltipText}>
        <Button disabled className={className} type='default' onClick={onClick}>
          {text}
        </Button>
      </Tooltip>
    ) : (
      <Button className={className} type='default' onClick={onClick}>
        {text}
      </Button>
    );
};

ButtonDraft.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.node,
  tooltipText: PropTypes.node,
  hasTooltip: PropTypes.bool,
};

export default ButtonDraft;
