import React from 'react';
import { Col } from 'antd';
import { ResetButton, SubmitButton } from 'formik-antd';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';



const ImageModalCardFooterEdit = ({ isSaveAndPublish, isImageAddingToBoard = false, isLoading = false }) => {
  const { isValid, dirty, handleReset, handleSubmit } = useFormikContext();
  const getSaveButtonText = isSaveAndPublish
    ? 'ui-general-save-and-publish'
    : isImageAddingToBoard
      ? 'planning-board-save-and-add-to-board'
      : 'ui-general-save';

  return (
    <>
      <Col>
        <ResetButton
          loading={isLoading}
          onClick={handleReset}
        >
          <IntlMessages id='ui-general-reset' />
        </ResetButton>
      </Col>
      <Col>
        <SubmitButton
          className='hp-ml-16'
          loading={isLoading}
          disabled={!isValid || !dirty}
          onClick={handleSubmit}
        >
          <IntlMessages id={getSaveButtonText} />
        </SubmitButton>
      </Col>
    </>
  );
};

ImageModalCardFooterEdit.propTypes = {
  isLoading: PropTypes.bool,
  isSaveAndPublish: PropTypes.bool,
  isImageAddingToBoard: PropTypes.bool,
};

export default ImageModalCardFooterEdit;
