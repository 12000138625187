import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';



const PlanningBoardImagesContext = createContext({});

export const usePlanningBoardImagesContext = () => {
  return useContext(PlanningBoardImagesContext);
};

export const PlanningBoardImagesProvider = ({ children }) => {
  const { pathname } = useLocation();
  const isEditing = pathname.endsWith('edit');
  const [ items, setItems ] = useState([]);
  const [ originalItems, setOriginalItems ] = useState([]);
  const [ currentlyAddedToBoardItem, setCurrentlyAddedToBoardItem ] = useState({});
  const [ imageWithErrorsIds, setErrorItemsIndexes ] = useState([]);

  useEffect(() => {
    if (!isEditing) {
      setErrorItemsIndexes([]);
      setCurrentlyAddedToBoardItem({});
      setItems(originalItems);
    }
  }, [ isEditing ]);

  const updateImageWithErrorsIds = (indexes) => {
    setErrorItemsIndexes(indexes);
  };

  const updateItems = (items) => {
    setItems(items);
  };

  const updateOriginalItems = (items) => {
    setOriginalItems(items);
  };

  const updateCurrentlyAddedToBoardItem = (item) => {
    setCurrentlyAddedToBoardItem(item);
  };

  const value = useMemo(() => {
    return {
      items,
      imageWithErrorsIds,
      currentlyAddedToBoardItem,
      originalItems,
      updateCurrentlyAddedToBoardItem,
      updateItems,
      updateImageWithErrorsIds,
      updateOriginalItems,
    };
  }, [ items, currentlyAddedToBoardItem, imageWithErrorsIds, originalItems ]);

  return (
    <PlanningBoardImagesContext.Provider value={value}>
      {children}
    </PlanningBoardImagesContext.Provider>
  );
};

PlanningBoardImagesProvider.propTypes = {
  children: PropTypes.node,
};
