import React from 'react';
import { Button, Card, Col, Descriptions, Dropdown, Menu, Popconfirm, Row, Skeleton, Space } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdOutlineUnpublished } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { PiGear, PiInfinity, PiPencilSimpleLine, PiTrashSimple } from 'react-icons/pi';
import { DownOutlined } from '@ant-design/icons';
import { RiArrowLeftLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import urlPagePopups from '../../../../../urls/urlPagePopups';
import urlPagePopupsPriority from '../../../../../urls/urlPagePopupsPriority';
import urlPagePopupsEdit from '../../../../../urls/urlPagePopupsEdit';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import useCheckMobileScreen from '../../../../../app/hooks/useCheckMobileScreen';
import ActionButton from '../../../../../layout/components/action-button';
import PublishedTag from '../../../../../components/PublishedTag';
import getFormattedDate from '../../../../../lib/getFormattedDate';
import mkPreparedTemplateData from '../../../../Content/utils/mkPreparedTemplateData';
import handleResponse from '../../../../../lib/handleResponse';
import TemplateCollapsibleCards from '../../../../../components/TemplateCollapsibleCards';
import getConfigurations from '../../../../../lib/getConfigurations';
import { POPUPS_SOURCE } from '../PagePopups/PagePopups.const';
import { TYPE_DICTIONARY } from '../../../../Template builder/const/templates';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  EMPTY_IMAGE_PLACEHOLDER, EMPTY_VALUE_PLACEHOLDER,
} from '../../../../../const/system';
import { useGetDictionaryQuery } from '../../../../Translations/api/dictionariesApiSlice';
import { useGetLanguagesQuery } from '../../../../Translations/api/languagesApiSlice';
import { useDeletePopupMutation, useGetSpecificPopupQuery, useUnpublishPopupMutation } from '../../../api/popupsApiSlice';



dayjs.extend(utc);

const PagePopupsDetails = () => {
  const { popupId } = useParams();
  const intl = useIntl();
  const isMobile = useCheckMobileScreen(600);
  const navigate = useNavigate();

  const { data: popup = {}, isFetching } = useGetSpecificPopupQuery({ id: popupId });
  const popupField = popup?.dynamic_data?.data?.find((item) => item.type === TYPE_DICTIONARY);

  const { data: popupDictionary = {} } = useGetDictionaryQuery({
    id: popupField?.value,
    include: 'translations,structure,structure.fields.translations',
  }, { skip: !popupField?.value });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  }, { skip: !popupField });

  const [ unpublishPopup, { isLoading: isUnpublishPopupLoading } ] = useUnpublishPopupMutation();
  const [ deletePopup, { isLoading: isPopupDeleting } ] = useDeletePopupMutation();

  const data = mkPreparedTemplateData({
    templateData: popup?.template?.data?.fields?.data,
    dynamicData: popup?.dynamic_data?.data,
    dictionary: popupDictionary,
    languages: languages?.languages,
  });

  const popupConfig = getConfigurations(POPUPS_SOURCE);
  const allowUnpublishPast = popupConfig?.publish?.allow_unpublish_past ?? true;
  const allowUpdatePast = popupConfig?.publish?.allow_update_past ?? true;
  const releaseDate = popup?.released_at;
  const allowUnpublish = allowUnpublishPast ?
    Boolean(popup?.is_published) :
    Boolean(popup?.is_published) && dayjs().isSameOrBefore(dayjs(releaseDate), 'day');
  const allowUpdate = allowUpdatePast ? true : dayjs().isSameOrBefore(dayjs(releaseDate).subtract(-1, 'days'), 'day');

  const handleUnpublish = () => {
    unpublishPopup({ popupId })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-unpublished-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-unpublish-failed', [], error));
  };

  const handleDelete = (popupId) => {
    deletePopup({ popupId })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-deleted-successfully',
        [
          () => navigate(urlPagePopups()),
        ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const actionItems = (
    <Menu>
      <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.PRIORITY.LIST ]}>
        {releaseDate && (
          <Menu.Item
            key="priority"
            icon={<PiGear size={18} />}
            onClick={() => {
              const preparedData = dayjs(releaseDate).format(DATE_FORMAT);

              return navigate({
                pathname: urlPagePopupsPriority(),
                search: `?search=date%3A${preparedData}`,
              });
            }}
          >
            <IntlMessages id="ui-general-priority" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.EDIT ]}>
        {allowUpdate && (
          <Menu.Item
            key="edit"
            icon={<PiPencilSimpleLine size={18} />}
            onClick={() => navigate(urlPagePopupsEdit({ popupId: popup.id }))}
          >
            <IntlMessages id="ui-general-edit" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.EDIT ]}>
        {allowUnpublish && (
          <Menu.Item
            key="unpublish"
            icon={<MdOutlineUnpublished size={18} />}
            onClick={handleUnpublish}
          >
            <IntlMessages id="ui-general-unpublish" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.DELETE ]}>
        {!popup.is_published &&
        <Popconfirm
          title={<IntlMessages id="ui-general-delete-confirm-message" />}
          placement="top"
          onConfirm={() => {
            handleDelete(popup.id);
          }}
        >
          <Menu.Item
            key="delete"
            icon={<PiTrashSimple size={18} />}
            onClick={() => {}}
          >
            <IntlMessages id="ui-general-delete" />
          </Menu.Item>
        </Popconfirm>}
      </PrivateComponent>
    </Menu>
  );

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPagePopups()}>
              <IntlMessages id='popups-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={popup?.title || '-'}
        />

        <Col md={10} span={24} className='text-end'>
          <Space size='middle'>
            <PrivateComponent
              allowedPermissions={[
                Permissions.OTHER.POPUPS.EDIT,
                Permissions.OTHER.POPUPS.DELETE,
                Permissions.OTHER.POPUPS.PRIORITY.LIST,
              ]}
            >
              <Dropdown overlay={actionItems}>
                <Button onClick={(ev) => ev.preventDefault()}>
                  <Space size='middle'>
                    <IntlMessages id='ui-general-action' />
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </PrivateComponent>

            <ActionButton
              title={intl.formatMessage({ id: 'ui-general-back-to-list' })}
              icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
              onClick={() => {
                navigate(urlPagePopups());
              }}
            />
          </Space>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card
            loading={isUnpublishPopupLoading || isPopupDeleting}
            className="hp-border-color-black-40 hp-card-6 dynamic-news-details"
            title={popup?.title}
          >
            {isFetching ? (
              <Row gutter={16}>
                <Col span={4}>
                  <Skeleton.Image active />
                </Col>
                <Col span={20}>
                  <Skeleton active />
                </Col>
              </Row>
            ) : (
              <Row gutter={[ 24, 24 ]}>
                <Col sm={24} md={6}>
                  <img src={popup?.preview ?? EMPTY_IMAGE_PLACEHOLDER} alt="preview" className="hp-mb-24" />
                </Col>
                <Col sm={24} md={18}>
                  <Descriptions
                    title={<IntlMessages id='popups-details-title' />}
                    layout={isMobile ? 'vertical' : 'horizontal'}
                    bordered
                    className="hp-mb-32"
                    column={1}
                  >
                    <Descriptions.Item label="ID" className='width-20-vw'>
                      <span style={{ wordBreak: 'keep-all' }}>{popup?.id}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-title" />}>
                      {popup?.title ?? EMPTY_VALUE_PLACEHOLDER}
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-template" />}>
                      {popup?.template?.data?.title ?? EMPTY_VALUE_PLACEHOLDER}
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-published" />}>
                      <PublishedTag
                        published={popup?.is_published}
                        publishedDate={popup?.published_at}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>
                      {getFormattedDate(popup.created_at, DATE_TIME_FORMAT)}
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-updated-at" />}>
                      {getFormattedDate(popup.updated_at, DATE_TIME_FORMAT)}
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-published-at" />}>
                      {getFormattedDate(popup.published_at, DATE_TIME_FORMAT)}
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-released-at" />}>
                      {getFormattedDate(popup.released_at, DATE_TIME_FORMAT)}
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-released-to" />}>
                      {!isEmpty(popup?.released_to) ? dayjs(popup?.released_to).utc().format(DATE_TIME_FORMAT) : <PiInfinity />}
                    </Descriptions.Item>
                  </Descriptions>

                  <TemplateCollapsibleCards
                    dictionary={popupDictionary}
                    languages={languages?.languages}
                    data={data}
                    targetData={popup?.target?.data}
                  />
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePopupsDetails;
