import { Link } from 'react-router-dom';
import { RiInformationLine } from 'react-icons/ri';
import { Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import appColors from '../../const/colors';
import IntlMessages from "../../layout/components/lang/IntlMessages";



const DocumentationTooltip = ({ link = '#', message = '' }) => {
  return (
    <Tooltip
      className='documentation-tooltip'
      placement="top"
      title={
        <>
          <IntlMessages id='features-title-notification' />
          &nbsp;
          <Link
            style={{ color: appColors.blueLink }}
            to={link}
            target="_blank"
          >
            https://x-flow-ltd.atlassian.net/wiki/...
          </Link>
        </>
      }
    >
      <RiInformationLine size={16} fill='blue' className='hp-ml-4 hp-cursor-pointer' />
    </Tooltip>
  );
};

DocumentationTooltip.propTypes = {
  link: PropTypes.string,
  message: PropTypes.string,
};


export default DocumentationTooltip;
