import React, { useState } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import { RiArrowLeftLine } from 'react-icons/ri';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPagePopups from '../../../../../urls/urlPagePopups';
import urlPagePopupsDetails from '../../../../../urls/urlPagePopupsDetails';
import transformErrors from '../../../../../lib/transformErrors';
import handleResponse from '../../../../../lib/handleResponse';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormCreatePopup from '../../../forms/FormCreatePopup';
import { useCreatePopupMutation } from '../../../api/popupsApiSlice';



const PagePopupCreate = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ errors, setFormErrors ] = useState({});

  const [ createPopup, { isLoading: isCreatingPopup } ] = useCreatePopupMutation();

  const onSubmit = (values) => {
    createPopup({ values })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-created-successfully',
        [
          () => navigate(urlPagePopupsDetails({ popupId: response?.data?.id })),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-create-failed',
        [ () => {
          if (isObject(error?.data?.errors)) {
            setFormErrors(transformErrors(error?.data?.errors));
          }
        } ],
        error,
      ));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPagePopups()}>
              <IntlMessages id='popups-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-create' />}
        />

        <Col md={12} span={24}>
          <Row
            gutter={[ 8, 8 ]}
            justify="end"
            align="middle"
          >
            <Col>
              <Space>
                <Link to={urlPagePopups()}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  >
                    <IntlMessages id='ui-general-back-to-list' />
                  </Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormCreatePopup
              isSubmitting={isCreatingPopup}
              initialErrors={errors}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePopupCreate;
