import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Card, Col, Modal, Row, Spin } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { Filter } from 'iconsax-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import isEqual from 'lodash/isEqual';
import urlPageFeeds from '../../../../../urls/urlPageFeeds';
import urlPageFeedsPlanningDetails from '../../../../../urls/urlPageFeedsPlanningDetails';
import urlPageFeedsPlanningDetailsEdit from '../../../../../urls/urlPageFeedsPlanningDetailsEdit';
import { FEEDS_SOURCE_MAP, getFeedsSourceTranslation, MODAL_MODE, searchFilterFields, ON_LOAD_LIMIT } from '../PageFeeds.const';
import { MYSTERY_CATEGORY_VALUE, NO_LINES_HASHTAG_VALUE } from '../../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import { DATE_FORMAT } from '../../../../../const/system';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import { imageStatuses } from '../../../../../const/imageStatuses';
import { hasRights, PrivateComponent } from '../../../../../components/HasRights/HasRights';
import resetFilter from '../../../../../lib/resetFilter';
import applyFilter from '../../../../../lib/applyFilter';
import useToggle from '../../../../../app/hooks/useToggle';
import getModalWidthByModalMode from '../../../../../lib/getModalWidthByModalMode';
import getModalTitleByModalMode from '../../../../../lib/getModalTitleByModalMode';
import getSourceFromUrl from '../../../../../lib/getSourceFromUrl';
import getFeedsNotificationLink, { SPECIFIC_DAY_PLANNING } from '../../../utils/getFeedsNotificationLink';
import useInfiniteScroll from '../../../../../app/hooks/useInfiniteScroll';
import getInitialImageCategoriesValues from '../../../../../lib/getInitialImageCategoriesValues';
import isNotReleased from '../../../../../lib/isNotReleased';
import { makeInitialEditImageErrors } from '../../../../../lib/makeInitialEditImageErrors';
import { getCardBackground } from '../../../components/FeedsPlanningBoardCalendar/FeedsPlanningBoardCalendarCells/FeedsPlanningBoardCalendarCells.const';
import DocumentationTooltip from '../../../../../components/DocumentationTooltip/DocumentationTooltip';
import Sidebar from '../../../../../components/Sidebar';
import ImageModalCard from '../../../components/ImageModalCard';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormFeedsFilter from '../../../forms/FormFeedsFilter';
import ActionButton from '../../../../../layout/components/action-button';
import Spinner from '../../../../../components/Spinner';
import TotalLabel from '../../../../../components/TotalLabel';
import ContentCard from '../../../components/ContentCard';
import ButtonPrimary from '../../../../../components/ButtonPrimary';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import PlanningBoardDetailsBadge from '../../../components/PlanningBoardDetailsBadge';
import FeedsCalendarWidget from '../../../components/FeedsCalendarWidget';
import FeedsPlanningBoardImages from '../../../components/FeedsPlanningBoardImages';
import PlanningBoardDetailsHeader from '../../../components/PlanningBoardDetailsHeader';
import { validationSchema } from '../../../forms/FormEditImage/FormEditImage.const';
import { usePlanningBoardImagesContext } from '../../../contexts/PlanningImagesContext';
import { useGetTagsQuery } from '../../../api/tagsApiSlice';
import { useGetFeedsQuery } from '../../../api/feedsApiSlice';
import { useGetFeedsPlanningBoardDetailsQuery } from '../../../api/feedsPlanningApiSlice';
import { useGetCategoriesQuery } from '../../../api/categoriesApiSlice';



const PageFeedsPlanningDetailsContent = () => {
  const navigate = useNavigate();
  const { date } = useParams();
  const { pathname } = useLocation();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ queryParams, setQuery ] = useState('');
  const [ modalMode, setModalMode ] = useState(MODAL_MODE.VIEW);
  const [ previewImage, setPreviewImage ] = useState({});
  const [ showContour, toggleShowContour ] = useToggle();
  const [ isFilterOpen, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ isAnimationStopped, setIsAnimationStopped ] = useState(false);
  const [ isUpdateList, setIsUpdateList ] = useState(false);
  const [ updatedListItem, setUpdatedListItem ] = useState({});
  const [ initialEditImageErrors, setInitialEditImageErrors ] = useState({});
  const [ isImageAddingToBoard, setIsImageAddingToBoard ] = useState(false);
  const [ isResetPlanningBoardImages, setResetPlanningBoardImages ] = useState(false);
  const [ isConfirmationModalOpen, setIsConfirmationModalOpen ] = useState(false);
  const [ storageTotalCount, setStorageTotalCount ] = useState(0);
  const [ navigationUrl, setNavigationUrl ] = useState('');

  const {
    items: planningBoardImages,
    originalItems: planningBoardOriginalImages,
    updateCurrentlyAddedToBoardItem,
    updateItems,
    updateOriginalItems,
    updateImageWithErrorsIds,
  } = usePlanningBoardImagesContext();

  //Default height of storage container (calculated initially)
  const initialStorageHeight = window.innerHeight - (window.innerWidth > 1997 ? 2000 : 1400);
  const [ maxStorageHeight, setMaxStorageHeight ] = useState(initialStorageHeight);

  const planningContainerRef = useRef(null);
  const scrollableDivRef = useRef(null);
  const storageFilterFormRef = useRef(null);

  const feedSource = getSourceFromUrl(pathname);
  const isEditing = pathname.endsWith('edit');
  const hasStoragePermission = hasRights([ Permissions.CONTENT.FEEDS.PLANNING.STORAGE ]);
  const hasPlanningPermission = hasRights([ Permissions.CONTENT.FEEDS.PLANNING.BOARD.DETAILS ]);

  const { data: {
    data,
    pagination,
  } = { data: [] },
  isFetching: isLoadingStorageImages,
  error: formErrors = {},
  } = useGetFeedsQuery({ feedSource, queryParams }, { refetchOnMountOrArgChange: true });

  const {
    data: { data: planningDetails } = { data: {} },
    isFetching: isLoadingPlanningDetails,
  } = useGetFeedsPlanningBoardDetailsQuery(date);

  const { data: {
    data: hashtags = [],
  } = { data: [], pagination: {} }, isFetching: isHashtagsFetching } = useGetTagsQuery({ queryParams: `limit=0&search=tag%3A${NO_LINES_HASHTAG_VALUE}` });

  const { data: {
    data: categories = [],
  } = { data: [], pagination: {} }, isFetching: isCategoriesFetching } = useGetCategoriesQuery({ queryParams: `limit=0&search=name%3A${MYSTERY_CATEGORY_VALUE}` });

  const noLinesHashtagId = hashtags[0]?.id;
  const mysteryCategoryId = categories[0]?.id;

  const {
    itemsArray,
    groupedItemsList,
    itemsLength,
    hasMore,
    handleLoadNextData,
    initFilterValues,
    setInitFilterValues,
    combinedQueryParams,
    setResetManuallyTriggered,
  } = useInfiniteScroll({
    data,
    pagination,
    searchFilterFields,
    source: feedSource,
    isUpdateList,
    updatedListItem,
  });

  const itemsInStorage = itemsArray.filter((item) => !planningBoardImages.find((boardItem) => item.id === boardItem.id));
  const itemsInStoragePrevLength = useRef(itemsInStorage.length);

  useEffect(() => {
    if (!isEmpty(combinedQueryParams)) {
      if (combinedQueryParams) {
        setQuery(combinedQueryParams);
      }

      if (combinedQueryParams === '') {
        setQuery('');
      }
    }
  }, [ combinedQueryParams ]);

  useEffect(() => {
    if (!isEmpty(initialEditImageErrors)) {
      setIsImageAddingToBoard(true);
      setModalMode(MODAL_MODE.EDIT);
      togglePreviewModal();
    }
  }, [ initialEditImageErrors ]);


  useEffect(() => {
    setStorageTotalCount(pagination?.total ?? 0);
  }, [ pagination?.total ]);

  useEffect(() => {
    const currentLength = itemsInStorage.length;
    const prevLength = itemsInStoragePrevLength.current;

    //Check step grade (plus/minus 1)
    if (prevLength && Math.abs(currentLength - prevLength) === 1) {
      if (currentLength > prevLength) {
        setStorageTotalCount((prev) => prev + 1);
      } else if (currentLength < prevLength) {
        setStorageTotalCount((prev) => prev - 1);
      }
    }
    itemsInStoragePrevLength.current = currentLength;
  }, [ itemsInStorage ]);

  useEffect(() => {
    //Calculate height interval which added to storage component
    const heightInterval = window.innerWidth < 1997 ? 1000 : 800;

    const changeHeight = () => {
      if (planningContainerRef.current && scrollableDivRef.current) {
        const { height: planningHeight } = planningContainerRef.current.getBoundingClientRect();

        if (maxStorageHeight < planningHeight) {
          if (itemsArray.length === 20) {
            setMaxStorageHeight(() => Math.min(heightInterval, planningHeight + 20));
          } else {
            setMaxStorageHeight((prevHeight) => Math.min(prevHeight + heightInterval, planningHeight + 20));
          }
        }
      }
    };

    changeHeight();
  }, [ itemsArray ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimationStopped(true);
    }, 10000);

    return () => {
      clearTimeout(timer);
      setIsAnimationStopped(false);
    };
  }, [ date ]);

  useEffect(() => {
    if (!isEmpty(planningDetails)) {
      setPreviewImage({});
      updateItems(planningDetails?.images);
      updateOriginalItems(planningDetails?.images);

      window.scrollTo(0, 0);
    }
  }, [ planningDetails ]);

  useEffect(() => {
    if (isResetPlanningBoardImages) {
      setResetPlanningBoardImages(false);
    }
  }, [ isResetPlanningBoardImages ]);

  useEffect(() => {
    if (itemsInStorage?.length === ON_LOAD_LIMIT && hasMore) {
      handleLoadNextData();
    }
  }, [ planningBoardImages ]);

  const handleUpdateListItem = (id, values = {}) => {
    setIsUpdateList(true);
    setUpdatedListItem({ id, values });
  };

  const handleUpdateTotal = () => {
    setStorageTotalCount((prev) => prev + 1);
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
    setInitialEditImageErrors({});
    setIsImageAddingToBoard(false);
  };

  const handleCloseEdit = () => {
    setStorageTotalCount(pagination?.total ?? 0);
    navigate(urlPageFeedsPlanningDetails({ feedSource: FEEDS_SOURCE_MAP.PLANNING, date }));
  };

  const handleCancelChanges = (isClear = false) => {
    storageFilterFormRef.current?.resetForm({});
    updateCurrentlyAddedToBoardItem({});
    updateImageWithErrorsIds([]);
    setResetPlanningBoardImages(true);
    updateItems(planningBoardOriginalImages);
    setStorageTotalCount(pagination?.total ?? 0);
    if (isClear) {
      setResetManuallyTriggered(true);
    }
  };

  const toggleUnpublishModal = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.UNPUBLISH);
    togglePreviewModal();
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleLeaveEditing = () => {
    handleCloseConfirmationModal();

    if (navigationUrl) {
      handleCancelChanges();
      navigate(navigationUrl);
    }
  };

  const showModal = (url) => {
    setIsConfirmationModalOpen(true);
    setNavigationUrl(url);
  };

  const handleClickOnBreadcrumbInEditing = (event, url) => {
    event.preventDefault();

    if (isEditing && !isEqual(planningBoardImages, planningBoardOriginalImages)) {
      showModal(url);
    } else {
      storageFilterFormRef.current?.resetForm({});
      navigate(url);
    }
  };

  const handleResetFilterAndNavigate = (url) => {
    storageFilterFormRef.current?.resetForm({});

    setTimeout(() => navigate(url), 0);
  };

  const validateOnAddToBoardBtnClick = (data) => {
    setInitialEditImageErrors({});

    const imageValidationSchema = validationSchema(
      data,
      noLinesHashtagId,
      mysteryCategoryId,
      true,
    );

    setPreviewImage(data);

    imageValidationSchema.validate({
      comment: data?.comment,
      labels: data?.labels,
      hashtags: data?.tags?.data?.map((tag) => tag.id),
      ...getInitialImageCategoriesValues(data),
    }, { abortEarly: false })
      .then(() => {
        updateCurrentlyAddedToBoardItem(data);
      })
      .catch((error) => makeInitialEditImageErrors(error, setInitialEditImageErrors));
  };

  const getStorageCardActions = useCallback((data) => {
    if (!isEditing) {
      return [];
    }

    return [ (
      <ButtonPrimary
        className='add-to-board-btn'
        key='add-to-board'
        text={<IntlMessages id='feeds-card-add-to-board' />}
        onClick={(event) => {
          event.target.closest('.add-to-board-btn')?.blur();
          validateOnAddToBoardBtnClick(data);
        }}
      />
    ) ];
  }, [ isEditing, data, mysteryCategoryId, noLinesHashtagId ]);

  const canUpdatePublicationTime = isNotReleased(previewImage.release_date)
    && previewImage.status !== imageStatuses.ready_for_release
    && !isEditing;

  const isEditDisabled = previewImage.status !== imageStatuses.release_on
      && isEditing
      && planningBoardImages?.some((boardItem) => previewImage.id === boardItem.id);

  return (
    <>
      <Modal
        title={<IntlMessages id="ui-general-leave-changes-confirm-modal-title" />}
        centered
        visible={isConfirmationModalOpen}
        onOk={handleLeaveEditing}
        onCancel={handleCloseConfirmationModal}
      >
        <IntlMessages id="ui-general-leave-changes-confirm-message" />
      </Modal>

      <Modal
        className='feed-modal'
        width={getModalWidthByModalMode(modalMode)}
        title={isImageAddingToBoard ? <IntlMessages id="planning-board-add-to-board" /> : getModalTitleByModalMode(modalMode)}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        destroyOnClose
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <ImageModalCard
          data={previewImage}
          modalMode={modalMode}
          handleModalClose={handlePreviewModalClose}
          handleUpdateListItem={(imageId, values = {}) => handleUpdateListItem(imageId, values)}
          handleModalMode={(mode) => setModalMode(mode)}
          canUpdatePublicationTime={canUpdatePublicationTime}
          initialEditImageErrors={initialEditImageErrors}
          isImageAddingToBoard={isImageAddingToBoard}
          editDisabled={modalMode === MODAL_MODE.UNPUBLISH || isEditDisabled}
          handleUpdateTotal={handleUpdateTotal}
        />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(
                event,
                urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.LIST }),
              )}
              to={urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.LIST })}
            >
              <IntlMessages id='sidebar-content-feeds' />
            </Link>
          }
          breadCrumbParent2={
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(
                event,
                urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.PLANNING }),
              )}
              to={urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.PLANNING })}
            >
              <IntlMessages id={getFeedsSourceTranslation(feedSource)} />
            </Link>
          }
          breadCrumbParent3={isEditing ? (
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(
                event,
                urlPageFeedsPlanningDetails({ feedSource: FEEDS_SOURCE_MAP.PLANNING, date }),
              )}
              to={urlPageFeedsPlanningDetails({ feedSource: FEEDS_SOURCE_MAP.PLANNING, date })}
            >
              {date}
            </Link>
          ) : null}
          breadCrumbActive={
            <div>
              {!isEditing ? (
                <div>
                  {date}
                  <DocumentationTooltip link={getFeedsNotificationLink(SPECIFIC_DAY_PLANNING)} />
                </div>
              ) : (
                <div>
                  <IntlMessages id='ui-general-edit' />
                  <DocumentationTooltip link={getFeedsNotificationLink(SPECIFIC_DAY_PLANNING)} />
                </div>
              )}
            </div>
          }
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <FeedsCalendarWidget
            isEditing={isEditing}
            isLoading={isLoadingPlanningDetails}
            toggleShowContour={toggleShowContour}
            showContour={showContour}
            initialPlanningBoardDetails={planningDetails}
            handleCancelChanges={handleCancelChanges}
            handleCloseEdit={handleCloseEdit}
            handleResetFilterAndNavigate={handleResetFilterAndNavigate}
            handleChangeCalendarDate={(date) => handleResetFilterAndNavigate(
              urlPageFeedsPlanningDetails({
                feedSource: FEEDS_SOURCE_MAP.PLANNING,
                date: date?.format(DATE_FORMAT),
              }),
            )}
            handleClearFeedsFilter={() => handleResetFilterAndNavigate(
              urlPageFeedsPlanningDetailsEdit({
                feedSource: FEEDS_SOURCE_MAP.PLANNING,
                date,
              }),
            )}
          />
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Sidebar
          visible={isFilterOpen}
          toggleSidebar={toggleFilterSidebar}
          width={600}
        >
          <FormFeedsFilter
            innerRef={storageFilterFormRef}
            isSubmitting={isLoadingStorageImages}
            initialValues={initFilterValues}
            formErrors={formErrors}
            feedSource={feedSource}
            onCancel={() => {
              setResetManuallyTriggered(true);
              setMaxStorageHeight(initialStorageHeight);
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              setMaxStorageHeight(initialStorageHeight);
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>

        <PrivateComponent allowedPermissions={[ Permissions.CONTENT.FEEDS.PLANNING.STORAGE ]}>
          <Col
            xs={24}
            md={12}
            lg={hasPlanningPermission ? 14 : 24}
            xxl={hasPlanningPermission ? 16 : 24}
          >
            <Spinner spinning={isLoadingStorageImages || isHashtagsFetching || isCategoriesFetching} tip=''>
              <Card
                width="100%"
                title={(
                  <div className="hp-d-flex hp-d-flex-between hp-align-items-center">
                    <IntlMessages id="ui-general-storage-images" />
                    <ActionButton
                      title=""
                      size='small'
                      isActive={!isEmpty(initFilterValues)}
                      className='hp-mr-sm-8'
                      icon={<Filter size={18} />}
                      onClick={toggleFilterSidebar}
                    />
                  </div>
                )}
              >
                <Row
                  justify='space-between'
                  className='hp-pb-18 center'
                  style={{ marginTop: '-6px', alignItems: 'center' }}
                >
                  <TotalLabel total={isEmpty(formErrors) ? storageTotalCount : 0} />
                </Row>

                <div
                  id="scrollableDiv"
                  ref={scrollableDivRef}
                  style={{ height: maxStorageHeight, overflow: 'auto' }}
                >
                  {!isEmpty(groupedItemsList) ? (
                    <InfiniteScroll
                      dataLength={itemsLength}
                      next={handleLoadNextData}
                      scrollableTarget="scrollableDiv"
                      scrollThreshold='100px'
                      hasMore={hasMore}
                      loader={isLoadingStorageImages && (
                        <div className='centered-item'><Spin /></div>
                      )}
                    >
                      <div className='content-card-grid content-card-grid--small hp-mb-32'>
                        {itemsInStorage.map((item) => (
                          <ContentCard
                            key={item.id}
                            data={item}
                            showContour={showContour}
                            onClick={handleCardClick}
                            actions={getStorageCardActions(item)}
                          />
                        ))}
                      </div>
                    </InfiniteScroll>
                  ) : (
                    <div className='hp-mt-64'>
                      <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
                    </div>
                  )}
                </div>
              </Card>
            </Spinner>
          </Col>
        </PrivateComponent>

        <PrivateComponent allowedPermissions={[ Permissions.CONTENT.FEEDS.PLANNING.BOARD.DETAILS ]}>
          <Col
            xs={24}
            md={12}
            lg={hasStoragePermission ? 10 : 24}
            xxl={hasStoragePermission ? 8 : 24}
          >
            <Spinner spinning={isLoadingPlanningDetails} tip=''>
              <PlanningBoardDetailsBadge
                date={date}
                isEditing={isEditing}
              >
                <div className={`feeds-calendar-widget ${isAnimationStopped ? 'stop-animation' : ''}`}>
                  <Card
                    width="100%"
                    title={<PlanningBoardDetailsHeader />}
                    className={getCardBackground(planningBoardImages?.length ?? 0, date)}
                  >
                    <div id='planningContainer' ref={planningContainerRef}>
                      <FeedsPlanningBoardImages
                        initialImages={planningBoardImages ?? []}
                        isEditing={isEditing}
                        isResetPlanningBoardImages={isResetPlanningBoardImages}
                        showContour={showContour}
                        handleCardClick={handleCardClick}
                        toggleUnpublishModal={toggleUnpublishModal}
                      />
                    </div>
                  </Card>
                </div>
              </PlanningBoardDetailsBadge>
            </Spinner>
          </Col>
        </PrivateComponent>
      </Row>
    </>
  );
};

export default PageFeedsPlanningDetailsContent;
