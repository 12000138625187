import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import getPriorityUpdateButtonTooltipMessage from '../../../../lib/getPriorityUpdateButtonTooltipMessage';
import applyFilter from '../../../../lib/applyFilter';
import handleResponse from '../../../../lib/handleResponse';
import Spinner from '../../../../components/Spinner';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import PrioritiesTitle from '../../../../components/PrioritiesTitle';
import FeaturePriorityList from '../../components/FeaturePriorityList';
import PriorityFilter from '../../../../components/PriorityFilter';
import { Permissions } from '../../../../const/permissions';
import { useGetFeaturesPriorityQuery, useUpdateFeaturesPriorityMutation } from '../../api/featuresApiSlice';



const PageFeaturesPriority = () => {
  const intl = useIntl();
  const [ featuresOrder, setFeaturesOrder ] = useState([]);
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const searchFilterFields = [ 'date' ];
  const isMobile = useCheckMobileScreen();

  const { data: featuresPriority = { data: [], pagination: {} }, isFetching } = useGetFeaturesPriorityQuery({
    queryParams: searchQueryParams.get('search') ? `search=${searchQueryParams.get('search')}&include=featured_source` : 'include=featured_source',
  });

  const [ updateFeaturesPriority, { isLoading: isUpdatingFeaturesPriority } ] = useUpdateFeaturesPriorityMutation();

  const today = dayjs().format(DATE_FORMAT);
  const search = searchQueryParams.get('search');
  const date = search ? search.split(':')[1] : today;
  const hasNoOrderChanges = !featuresOrder.length;
  const isPrevPriority = dayjs(date).isBefore(dayjs().subtract(2, 'day'));

  const onFeaturesOrderChange = (order) => {
    setFeaturesOrder(order);
  };

  const submitFeaturesPriority = () => {
    const order = featuresOrder.map((item) => {
      const position = Number(item) - 1;

      return featuresPriority.data[position]?.id;
    });

    updateFeaturesPriority({ order, date })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [ () => setFeaturesOrder([]) ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='ui-general-priority' />} />
      </Row>

      <Row gutter={[ 16, 16 ]} style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
        <Col sm={24} md={16}>
          <Card
            className="hp-border-color-black-40 hp-card-6"
            title={(
              <PrioritiesTitle
                isDisabled={hasNoOrderChanges || isPrevPriority}
                hasPermission={HasRights([ Permissions.FEATURED.FEATURES.PRIORITY.UPDATE ])}
                title={intl.formatMessage({ id: 'features-priority-title' })}
                onAction={submitFeaturesPriority}
                tooltipMessage={getPriorityUpdateButtonTooltipMessage(hasNoOrderChanges, isPrevPriority)}
              />
            )}
          >
            <Spinner spinning={isFetching || isUpdatingFeaturesPriority}>
              <FeaturePriorityList
                data={featuresPriority?.data}
                isLoading={isFetching || isUpdatingFeaturesPriority}
                selectedDate={date}
                onOrderChange={onFeaturesOrderChange}
              />
            </Spinner>
          </Card>
        </Col>
        <Col sm={24} md={8}>
          <Card
            title={intl.formatMessage({ id: 'features-priority-filter-title' })}
            className="hp-border-color-black-40 hp-card-6"
          >
            <PriorityFilter
              isSubmitting={isFetching || isUpdatingFeaturesPriority}
              initialValues={searchQueryParams.get('search') ? { date } : {}}
              onCancel={() => {}}
              onSubmit={(values) => {
                applyFilter({
                  values,
                  searchQueryParams,
                  searchFilterFields,
                  setSearchParams,
                  callbacks: [ () => setFeaturesOrder([]) ],
                });
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageFeaturesPriority;
