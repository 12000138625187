import React from 'react';
import { Alert, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import urlPageCollectionDetails from '../../../../../urls/urlPageCollectionDetails';
import urlPageFeatureDetails from '../../../../../urls/urlPageFeatureDetails';
import { FEATURES_SOURCE_MAP } from '../../../../Featured/pages/PageFeatures/PageFeatures.const';
import PreviewImages from '../../PreviewImages';
import PreviewImageDescription from '../../PreviewImageDescription';
import ImageModalCardHeader from '../ImageModalCardHeader';



const ImageModalCardBodyView = ({ data }) => {
  const makeAlertMessage = () => {
    const alertElements = [ 'Unpublish is disabled because image is attached:' ];

    if (data?.used_in_collections?.length) {
      alertElements.push(
        ' to collections with ID\'s: ',
        data.used_in_collections.map((id, index) => (
          <React.Fragment key={`collection-${id}`}>
            {index > 0 && ', '}
            <Link
              className='alert-message-danger-link'
              to={urlPageCollectionDetails({ collectionId: id })}
            >
              {id}
            </Link>
          </React.Fragment>
        )),
        '.',
      );
    }

    if (data?.used_in_editor_choices?.length) {
      alertElements.push(
        ' to editor\'s choices with IDs: ',
        data.used_in_editor_choices.map((id, index) => (
          <React.Fragment key={`editor-choice-${id}`}>
            {index > 0 && ', '}
            <Link
              className='alert-message-danger-link'
              to={urlPageFeatureDetails({ featureSource: FEATURES_SOURCE_MAP.EDITOR_CHOICE, featureId: id })}
            >
              {id}
            </Link>
          </React.Fragment>
        )),
        '.',
      );
    }

    return <span>{alertElements}</span>;
  };

  return (
    <>
      <ImageModalCardHeader data={data} />
      <Row gutter={[ 32, 32 ]} justify="center" className='hp-my-16 hp-border-top-1'>
        <PreviewImages
          icon={data?.icon?.data?.path}
          preview={data?.preview?.data?.path}
        />
        <Col xs={24} md={6}>
          <PreviewImageDescription data={data} />
        </Col>
      </Row>
      {(data?.used_in_collections || data?.used_in_editor_choices) && (
        <Row gutter={[ 32, 32 ]} justify="center">
          <Alert
            className='hp-p-16 hp-mt-8 hp-mb-16'
            message={makeAlertMessage()}
            type="error"
            showIcon
          />
        </Row>
      )}
    </>
  );
};

ImageModalCardBodyView.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
  }),
  isLoading: PropTypes.bool,
};

export default ImageModalCardBodyView;
