import React from 'react';
import { PlanningBoardImagesProvider } from '../../../contexts/PlanningImagesContext';
import PageFeedsPlanningDetailsContent from './PageFeedsPlanningDetailsContent';



const PageFeedsPlanningDetails = () => {
  return (
    <PlanningBoardImagesProvider>
      <PageFeedsPlanningDetailsContent />
    </PlanningBoardImagesProvider>
  );
};

export default PageFeedsPlanningDetails;
