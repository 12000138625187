import dayjs from 'dayjs';
import React from 'react';
import moment from 'moment/moment';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { DATE_FORMAT } from '../../../../const/system';



const validationSchema = Yup.object().shape({
  range: Yup.array().required('Choose range'),
  month: Yup.string().nullable(),
  year: Yup.string().nullable(),
});

const monthOptions = [
  {
    value: '01-01',
    label: <IntlMessages id="ui-general-jan-short" />,
  },
  {
    value: '02-01',
    label: <IntlMessages id="ui-general-feb-short" />,
  },
  {
    value: '03-01',
    label: <IntlMessages id="ui-general-mar-short" />,
  },
  {
    value: '04-01',
    label: <IntlMessages id="ui-general-apr-short" />,
  },
  {
    value: '05-01',
    label: <IntlMessages id="ui-general-may-short" />,
  },
  {
    value: '06-01',
    label: <IntlMessages id="ui-general-jun-short" />,
  },
  {
    value: '07-01',
    label: <IntlMessages id="ui-general-jul-short" />,
  },
  {
    value: '08-01',
    label: <IntlMessages id="ui-general-aug-short" />,
  },
  {
    value: '09-01',
    label: <IntlMessages id="ui-general-sep-short" />,
  },
  {
    value: '10-01',
    label: <IntlMessages id="ui-general-oct-short" />,
  }, {
    value: '11-01',
    label: <IntlMessages id="ui-general-nov-short" />,
  },
  {
    value: '12-01',
    label: <IntlMessages id="ui-general-dec-short" />,
  },
];

const yearOptions = () => {
  const currentYear = dayjs().format('YYYY');
  const options = [];

  // eslint-disable-next-line fp/no-loops
  for (let i = Number(currentYear) - 7; i < Number(currentYear) + 7; i++) {
    options.push({
      value: i.toString(),
      label: i,
    });
  }

  return options;
};

const defaultDateFrom = dayjs().format(DATE_FORMAT);

const defaultDateTo = dayjs().add(13, 'days').format(DATE_FORMAT);

const defaultInitValues = {
  range: [ moment(), moment().add(13, 'days') ],
  month: dayjs().startOf('month').format('MM-DD'),
  year: dayjs().format('YYYY'),
};

export { validationSchema, monthOptions, yearOptions, defaultDateFrom, defaultDateTo, defaultInitValues };

