import { EMPTY_VALUE_PLACEHOLDER } from '../../../const/system';
import { FEEDS_SOURCE_MAP } from '../pages/Feeds/PageFeeds.const';



export const SPECIFIC_DAY_PLANNING = 'specific_day_planning';

const getFeedsNotificationLink = (source) => {
  switch (source) {
    case FEEDS_SOURCE_MAP.PLANNING: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/1101299818/Planning';
    case SPECIFIC_DAY_PLANNING: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/1116930057/Specific+day+planning';
    case FEEDS_SOURCE_MAP.LIST: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/1057849357/List';
    case FEEDS_SOURCE_MAP.RELEASED: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/1058635806/Released';
    case FEEDS_SOURCE_MAP.ARCHIVED: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/1058930697/Archived';
    case FEEDS_SOURCE_MAP.TRASHED: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/1059094529/Trashed';

    default: return EMPTY_VALUE_PLACEHOLDER;
  }
};

export default getFeedsNotificationLink;
