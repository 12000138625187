import React, { useState } from 'react';
import { Card, Col, Modal, Pagination, Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { RiCloseFill } from 'react-icons/ri';
import { Filter } from 'iconsax-react';
import { useParams, useSearchParams } from 'react-router-dom';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import useToggle from '../../../../app/hooks/useToggle';
import useScrollToImagesOnSearch from '../../../Content/hooks/useScrollToImagesOnSearch';
import getFeatureConfiguration from '../../utils/getFeatureConfiguration';
import applyFilter from '../../../../lib/applyFilter';
import resetFilter from '../../../../lib/resetFilter';
import handleResponse from '../../../../lib/handleResponse';
import TotalLabel from '../../../../components/TotalLabel';
import ActionButton from '../../../../layout/components/action-button';
import ContentCard from '../../../Content/components/ContentCard';
import FormImagesFilter from '../../../Content/forms/FormImagesFilter';
import Sidebar from '../../../../components/Sidebar';
import { FEATURES_SOURCE_MAP } from '../../pages/PageFeatures/PageFeatures.const';
import { searchFilterFields } from '../../../Content/forms/FormImagesFilter/FormImagesFilter.const';
import { useGetImagesQuery } from '../../../Content/api/imagesApiSlice';
import { useAttachImageToFeatureMutation } from '../../api/featuresApiSlice';



const FormAttachImagesToFeature = ({ featureSource }) => {
  const intl = useIntl();
  const { featureId } = useParams();
  const [ isFilterOpen, toggleFilterSidebar ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ confirmModalOpened, setConfirmModalOpened ] = useState(false);
  const [ selectedItemId, setSelectedItemId ] = useState(null);

  const [ attachImage ] = useAttachImageToFeatureMutation();
  const featureConfig = getFeatureConfiguration(featureSource);
  const imageStatuses = featureConfig?.config?.content?.statuses?.join(',') ?? null;

  const {
    pagination,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
  } = useQueryParams({
    searchFilterFields,
    paginationInit: { page: 1, limit: 12 } });

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching: isImagesFetching } = useGetImagesQuery({
    queryParams: searchParams.toString().includes('search')
      ? `${searchParams.toString()};status:${imageStatuses}`
      : `${searchParams.toString()}&search=status:${imageStatuses}`,
  });

  const imagesRef = useScrollToImagesOnSearch(images, searchQueryParams);

  const handleAttach = (imageId) => {
    attachImage({
      featureSource,
      featureId,
      payload: { content_image_id: imageId },
    })
      .unwrap()
      .then(() => handleResponse('success', intl, 'features-edit-images-to-attach-success'))
      .catch((error) => handleResponse('error', intl, 'features-edit-images-to-attach-failed', [], error));
  };

  const showTotal = (total) => <TotalLabel total={total} />;

  const handleContentCardClick = (item) => {
    setSelectedItemId(item.id);
    setConfirmModalOpened(true);
  };

  const handleModalConfirm = () => {
    handleAttach(selectedItemId, imageStatuses.achievement);
    setConfirmModalOpened(false);
  };

  return (
    <>
      <Sidebar
        visible={isFilterOpen}
        toggleSidebar={toggleFilterSidebar}
        width={600}
      >
        <FormImagesFilter
          isSubmitting={false}
          initialValues={initFilterValues}
          excludedFields={[ 'status', 'released_from', 'released_to' ]}
          onCancel={() => {
            resetFilter({
              searchQueryParams,
              setSearchParams,
              setInitFilterValues,
            });
          }}
          onSubmit={(values) => {
            applyFilter({
              values,
              searchQueryParams,
              searchFilterFields,
              setSearchParams,
              toggleFilterSidebar,
            });
          }}
        />
      </Sidebar>
      <Modal
        width={600}
        title={intl.formatMessage({ id: 'ui-general-attach-image' })}
        centered
        visible={confirmModalOpened}
        onCancel={() => {
          setConfirmModalOpened(false);
        }}
        onOk={handleModalConfirm}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <IntlMessages id="features-edit-images-to-attach-confirm-message" />
      </Modal>
      <Col span={24} ref={imagesRef}>
        <Card
          loading={isImagesFetching}
          title={(
            <div className="hp-d-flex hp-d-flex-between">
              <IntlMessages
                id={featureSource === FEATURES_SOURCE_MAP.EDITOR_CHOICE
                  ? 'ui-general-ready-release-on'
                  : 'ui-general-storage-images'}
              />
              <ActionButton
                title=""
                isActive={!isEmpty(initFilterValues)}
                icon={<Filter size={18} />}
                onClick={toggleFilterSidebar}
              />
            </div>
          )}
          className="hp-border-color-black-40 hp-card-6"
        >
          {!isEmpty(images) && (
            <Row gutter={[ 16, 32 ]}>
              {images?.map((item) => {
                return (
                  <Col
                    key={item.id}
                    className='centered-item'
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xxl={4}
                  >
                    <ContentCard
                      data={item}
                      useTooltip
                      onClick={() => handleContentCardClick(item)}
                    />
                  </Col>
                );
              })}
              <Col span={24}>
                {dataPagination.total && <Pagination
                  total={dataPagination.total}
                  showTotal={showTotal}
                  pageSize={pagination.limit}
                  current={pagination.page}
                  pageSizeOptions={[ '12', '24', '48' ]}
                  onChange={(page, size) => {
                    handleChangeTableParams({
                      current: page,
                      pageSize: size,
                    }, {}, {});
                  }}
                />}
              </Col>
            </Row>
          )}
          {!isImagesFetching && isEmpty(images) && (
            <Row>
              <IntlMessages id="ui-general-no-images-found" />
            </Row>
          )}
        </Card>
      </Col>
    </>
  );
};

FormAttachImagesToFeature.propTypes = {
  featureSource: PropTypes.string,
};

export default FormAttachImagesToFeature;
