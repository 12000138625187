import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, Col, Input, Modal, Row, Space, Table, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { Filter } from 'iconsax-react';
import { useSearchParams } from 'react-router-dom';
import { getNewsTableColumns } from './PageNews.const';
import { useGetLanguagesQuery } from '../../../../Translations/api/languagesApiSlice';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../../const/pagination';
import useToggle from '../../../../../app/hooks/useToggle';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useElementSize from '../../../../../app/hooks/useElementSize';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import handleResponse from '../../../../../lib/handleResponse';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Sidebar from '../../../../../components/Sidebar';
import FormCreateNews from '../../../forms/FormCreateNews';
import NewsPreview from '../../../components/NewsPreview';
import FormNewsFilter from '../../../forms/FormNewsFilter';
import FormUpdateNews from '../../../forms/FormUpdateNews';
import {
  useCreateNewsMutation,
  useDeleteNewsMutation,
  useGetNewsQuery,
  useUpdateNewsMutation,
} from '../../../api/newsApiSlice';



const { Title } = Typography;

const PageNews = () => {
  const intl = useIntl();
  const [ elementRef, size ] = useElementSize();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ isVisibleCreateSidebar, toggleCreateSidebar ]  = useToggle();
  const [ isVisibleUpdateSidebar, toggleUpdateSidebar ]  = useToggle();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ]  = useToggle();
  const [ isVisiblePreviewNews, togglePreviewModal ] = useToggle();
  const [ currentNews, setCurrentNews ] = useState({});

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'status', 'type', 'published_from', 'published_to' ];

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields });

  const { data = { data: [], pagination: {} }, isFetching } = useGetNewsQuery({
    queryParams: `${searchParams.toString()}`,
  });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  });

  const [ createNews, { isLoading: isCreatingNews } ] = useCreateNewsMutation();
  const [ updateNews, { isLoading: isUpdatingNews } ] = useUpdateNewsMutation();
  const [ deleteNews, { isLoading: isDeletingNews } ] = useDeleteNewsMutation();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateNews = (data, resetForm) => {
    createNews(data)
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-created-successfully',
        [ toggleCreateSidebar, resetForm ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-create-failed', [], error));
  };

  const handleUpdateNews = (id, data) => {
    toggleUpdateSidebar();
    updateNews({ id, values: data })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-updated-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  const handleView = (news) => {
    setCurrentNews(news);
    togglePreviewModal();
  };

  const handleClosePreview = () => {
    setCurrentNews({});
    togglePreviewModal();
  };

  const handleEdit = (news) => {
    setCurrentNews(news);
    toggleUpdateSidebar();
  };

  const handleDeleteNews = (id) => {
    deleteNews(id)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='news-breadcrumbs' />} />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <ActionButton
                  title={<IntlMessages id='ui-general-create' />}
                  icon={<RiAddLine className="btn-icon-mr-1" />}
                  onClick={toggleCreateSidebar}
                />
                <ActionButton
                  title=""
                  isActive={!isEmpty(initFilterValues)}
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Modal
          title={<IntlMessages id="news-preview-title" />}
          width="65vw"
          centered
          destroyOnClose
          visible={isVisiblePreviewNews}
          onCancel={handleClosePreview}
          footer={null}
          closeIcon={
            <RiCloseFill className="remix-icon text-color-black-100" size={24} />
          }
        >
          <NewsPreview data={currentNews} languages={languages.languages} />
        </Modal>

        <Sidebar
          title={<Title level={5} >{intl.formatMessage({ id: 'news-create-title' })}</Title>}
          visible={isVisibleCreateSidebar}
          toggleSidebar={toggleCreateSidebar}
        >
          <FormCreateNews
            isSubmitting={false}
            onSubmit={handleCreateNews}
            onCancel={() => {}}
          />
        </Sidebar>

        <Sidebar
          title={
            <Title level={5} >
              {intl.formatMessage({ id: 'news-update-title' })}
              . Id:
              &nbsp;
              {currentNews?.id}
            </Title>
          }
          visible={isVisibleUpdateSidebar}
          toggleSidebar={toggleUpdateSidebar}
        >
          <FormUpdateNews
            isSubmitting={false}
            initialValues={currentNews}
            onSubmit={handleUpdateNews}
            onCancel={() => {}}
          />
        </Sidebar>

        <Sidebar
          title={<Title level={5}>{intl.formatMessage({ id: 'news-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormNewsFilter
            initialValues={initFilterValues}
            isSubmitting={false}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField: setSearchTerm,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: size.width }}
                loading={makeTableLoadingObject(isFetching || isCreatingNews || isUpdatingNews || isDeletingNews)}
                rowKey="id"
                columns={getNewsTableColumns(handleView, handleEdit, handleDeleteNews, languages.languages, size)}
                dataSource={data.data}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: data.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageNews;
