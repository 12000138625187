import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const PresetsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Presets', 'Preset' ],
  endpoints: (builder) => {
    return ({
      getPresets: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/presets${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Presets' ],
      }),
      createPreset: builder.mutation({
        query: ({ values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/presets`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Presets', 'Template' ],
      }),
      updatePreset: builder.mutation({
        query: ({ values, id }) => (
          {
            url: `/${Config.VITE_HC_API_VERSION}/presets/${id}`,
            method: 'POST',
            body: values,
          }
        ),
        invalidatesTags: () => [ 'Presets', 'Template', 'Preset' ],
      }),
      getPreset: builder.query({
        query: (params) => {
          const baseUrl = `/${Config.VITE_HC_API_VERSION}/presets/${params.id}`;
          const includeParam = params?.include ? `?include=${params.include}` : '';

          return { url: baseUrl + includeParam };
        },
        transformResponse: (response) => response.data,
        providesTags: () => [ { type: 'Preset' } ],
      }),
      deletePreset: builder.mutation({
        query: ({ id }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/presets/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'Presets', 'Template' ],
      }),
    });
  },
});

export const {
  useGetPresetsQuery,
  useCreatePresetMutation,
  useUpdatePresetMutation,
  useGetPresetQuery,
  useDeletePresetMutation,
} = PresetsApiSlice;
