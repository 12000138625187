const feeds = {
  'feeds-breadcrumbs-list': 'Feeds',
  'feeds-breadcrumbs-planning': 'Planning',
  'feeds-breadcrumbs-released-list': 'Released',
  'feeds-breadcrumbs-archived-list': 'Archive',
  'feeds-breadcrumbs-trash-list': 'Trash',
  'feeds-form-very-simple-difficulty': 'Very simple',
  'feeds-form-simple-difficulty': 'Simple',
  'feeds-form-medium-difficulty': 'Medium',
  'feeds-form-hard-difficulty': 'Hard',
  'feeds-form-difficulty-tooltip': 'Current options predefine taps min/max values',
  'feeds-list-unpublish-disabled-tooltip': 'Cannot unpublish image, because image is attached to collection or/and to editor\'s choice data.',
  'feeds-trash-modal-remove-text': 'Attention! The picture will be completely deleted, it cannot be found in the panel and cannot be used.',
  'feeds-archive-modal-trash-text': 'Image will be trashed, are you sure you want to proceed?',
  'feeds-trash-modal-return-to-draft-confirm': 'Are you sure you want to return image to draft?',
  'feeds-unpublish-modal-trash-confirm': 'Are you sure you want to trash image?',
  'feeds-unpublish-modal-archive-confirm': 'Are you sure you want to archive image?',
  'feeds-modal-unpublish-archive-text': 'If you click on the Archive, the picture will be unpublished, but will remain available for synchronization.',
  'feeds-modal-unpublish-trash-text': 'If you click the Trash button, the picture will be completely unpublished and can be deleted.',
  'feeds-modal-unpublish-image-in-prod': 'Image is in production for all or part of the world.',
  'feeds-modal-edit-image-save-and-publish-info': 'We inform you about the availability of updates for the Happy Color mobile app. To successfully publish these changes, click on the \'Save & Publish\' button.',
  'feeds-modal-edit-image-save-info': 'We inform you about the availability of updates for the Happy Color mobile app. To successfully publish these changes, click on the \'Save\' button.',
  'feeds-planning-board': 'Board',
  'feeds-calendar-today-cell': 'Today',
  'feeds-calendar-filter-info': 'Filter range is set for the next 2 weeks by default (i.e. today + 13 days).',
  'feeds-image-modal-publication-time-info': 'Local time for the player',
  'feeds-calendar-median': 'Median',
  'feeds-calendar-average': 'Average',
  'feeds-card-add-to-board': 'Add to board',
  'planning-board-status-info': 'Colors information:',
  'planning-board-status-info-red': 'Red - The selected date is less than two weeks away and there is not enough planned content for it. Min: 15 images. Please add missing content promptly.',
  'planning-board-status-info-yellow': 'Yellow - Not enough content planned for future date. Min: 15 images.',
  'planning-board-status-info-green': 'Green - The minimum planned content requirement (15 images) has been reached for the selected date.',
  'planning-board-clear-confirm-message': 'Are you sure you want to clear the planning board for this date?',
  'planning-board-disabled-clear-hint': 'Cannot clear planning board for this date, there are published pictures.',
  'planning-board-add-to-board': 'Edit & add to board',
  'planning-board-save-and-add-to-board': 'Save & add to board',
  'planning-board-unpublish-warning': 'You have unsaved changes on your board. After unpublish current image all unsaved changes will be lost. Do you want to proceed?',
  'planning-board-has-errors': 'Please update any broken images (highlighted in red) before posting the board.',
};

export default feeds;
