import { Tag, Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import appColors from '../../../../const/colors';



const BeeLink = ({ link, title }) => {
  const canViewBeepanel = HasRights([ Permissions.CONTENT.IMAGES.VIEW_BEEPANEL ]);

  if (canViewBeepanel) {
    return (
      <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
        <Tag>
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{ color: appColors.lightIndigo, fontSize: '13px' }}
          >
            {`Bee: ${title}`}
          </a>
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Tag>
      <span>{`Bee: ${title}`}</span>
    </Tag>
  );
};

BeeLink.propTypes = {
  link: PropTypes.string,
  title: PropTypes.any,
};

export default BeeLink;
