import React from 'react';
import { Col, Row } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RiArrowLeftLine } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageRBACLogs from '../../../../../urls/urlPageRBACLogs';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import Spinner from '../../../../../components/Spinner';
import ActionButton from '../../../../../layout/components/action-button';
import LogDetailsCard from '../../../components/LogDetailsCard';
import LogDetailsDiffCard from '../../../components/LogDetailsDiffCard';
import { mkPreparedData } from './PageLogDetails.const';
import { useGetSpecificLogQuery } from '../../../api/logsApiSlice';



const PageLogDetails = () => {
  const { logId } = useParams();
  const navigate = useNavigate();

  const { data: logDetails = {}, isLoading } = useGetSpecificLogQuery(logId);

  const logData = !isEmpty(logDetails?.params?.diff) ? logDetails.params?.diff : {};
  const hasDiff = logData.hasOwnProperty('before');

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageRBACLogs()}>
              <IntlMessages id='logs-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={logId}
        />

        <Col>
          <ActionButton
            icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
            title={<IntlMessages id='ui-general-back-to-list' />}
            onClick={() => {
              navigate(urlPageRBACLogs());
            }}
          />
        </Col>
      </Row>

      <Col span={24}>
        <Spinner spinning={isLoading}>
          <LogDetailsCard data={logDetails} />

          <LogDetailsDiffCard
            hasDiff={hasDiff}
            isLoading={isLoading}
            data={mkPreparedData(logData, hasDiff)}
          />
        </Spinner>
      </Col>
    </>
  );
};

export default PageLogDetails;
