import React from 'react';
import { Form, FormItem, Input, ResetButton, SubmitButton, Switch } from 'formik-antd';
import { Formik } from 'formik';
import { Alert, Col, DatePicker, Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import appColors from '../../../../const/colors';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../const/permissions';
import { BONUSES_SOURCE } from '../../pages/Bonuses/PageBonuses/PageBonuses.const';
import { TIME_FORMAT } from '../../../../const/system';
import { DEFAULT_RELEASE_TIME } from '../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import { mkValidationSchema } from './FormCreateEditBonus.const';
import { HasRights } from '../../../../components/HasRights/HasRights';
import getConfigurations from '../../../../lib/getConfigurations';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import isDateDisabled from '../../../../lib/isDateDisabled';
import disableDateBeforeYesterday from '../../../../lib/disableDateBeforeYesterday';



const FormCreateEditBonus = ({
  initialValues,
  initialErrors,
  onSubmit,
  onCancel,
  isSubmitting,
  isEdit = false,
  hasReleasedImages = false,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  const bonusesConfig = getConfigurations(BONUSES_SOURCE);
  const useDatePickerTime = bonusesConfig?.publish?.use_time ?? false;
  const hasReleaseRange = bonusesConfig?.release_range ?? false;

  const validationSchema = mkValidationSchema(bonusesConfig, initialValues);

  if (!isEmpty(initialErrors)) {
    window.scrollTo(0, 0);
  }

  return (
    <Formik
      enableReinitialize
      isSubmitting
      validationSchema={validationSchema}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={async (values, { resetForm }) => {
        const { updated_at, ...restValues } = values;

        await onSubmit(restValues, resetForm);
      }}
      onReset={async (values) => {
        onCancel(values);
      }}
    >
      {({ values, isValid, dirty, setFieldValue, setFieldTouched }) => {
        const hasUnpublishedChanges = !moment(values?.published_at).isSame(moment(values?.updated_at));

        if (!hasReleasedImages && values?.is_published) {
          setFieldValue('is_published', false);
        }

        return (
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={isEdit ? 12 : 24}>
                <FormItem
                  name='title'
                  required
                  label={<IntlMessages id="ui-general-title" />}
                >
                  <Input
                    name="title"
                    placeholder={intl.formatMessage({ id: 'ui-general-specify-value' })}
                  />
                </FormItem>
              </Col>

              <Col span={isEdit ? 12 : 24}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-code' })}
                  name='code'
                  required
                >
                  <Input
                    disabled={isEdit}
                    name="code"
                    placeholder={intl.formatMessage({ id: 'ui-general-specify-code' })}
                  />
                </FormItem>
              </Col>

              {hasReleaseRange ? (
                <>
                  <Col span={isEdit ? 12 : 24}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-released-at' })}
                      name='released_at'
                      required={values?.is_published}
                    >
                      <DatePicker
                        name='released_at'
                        showToday
                        showTime={useDatePickerTime
                          ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT) }
                          : false}
                        disabledDate={(date) => isDateDisabled(
                          date,
                          [],
                          !isEmpty(values?.released_to)
                          && date.isAfter(moment(values?.released_to)),
                        )}
                        value={values?.released_at ? moment(values.released_at).utc(false) : null}
                        onChange={async (date, dateString) => {
                          await setFieldValue('released_at', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                        }}
                        onBlur={async ({ target }) => {
                          await setFieldTouched('released_at', true);
                          await onBlur('released_at', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={isEdit ? 12 : 24}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-released-to' })}
                      name='released_to'
                    >
                      <DatePicker
                        name='released_to'
                        showTime={useDatePickerTime
                          ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT) }
                          : false}
                        disabledDate={(date) => isDateDisabled(
                          date,
                          [],
                          !isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at)),
                        )}
                        value={values?.released_to ? moment(values.released_to).utc(false) : null}
                        onChange={async (date, dateString) => {
                          await setFieldValue('released_to', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                        }}
                        onBlur={async ({ target }) => {
                          await setFieldTouched('released_to', true);
                          await onBlur('released_to', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                        }}
                      />
                    </FormItem>
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-released-at' })}
                    name='released_at'
                    required={values?.is_published}
                  >
                    <DatePicker
                      name='released_at'
                      showToday
                      showTime={useDatePickerTime
                        ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT) }
                        : false}
                      disabledDate={disableDateBeforeYesterday}
                      value={values?.released_at ? moment(values.released_at) : null}
                      onChange={async (date, dateString) => {
                        await setFieldValue('released_at', dateString);
                      }}
                      onBlur={async ({ target }) => {
                        await setFieldTouched('released_at', true);
                        await onBlur('released_at', target.value, setFieldValue);
                      }}
                    />
                  </FormItem>
                </Col>
              )}

              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-description' })}
                  name='description'
                >
                  <Input.TextArea
                    rows={3}
                    maxLength={1000}
                    name="description"
                    type="text"
                    placeholder={intl.formatMessage({ id: 'ui-general-specify-description' })}
                  />
                </FormItem>
              </Col>
            </Row>


            {isEdit && hasReleasedImages && (dirty || hasUnpublishedChanges) && (
              <Alert
                className='hp-mb-32'
                message={
                  <>
                    <IntlMessages id="features-edit-has-changes" />
                    {!HasRights([ Permissions.CONTENT.BONUSES.UNPUBLISH ]) && (
                      <div className='hp-mt-12' style={{ color: appColors.lightRed }}>
                        <IntlMessages id="bonus-edit-notification-note" />
                      </div>
                    )}
                  </>
                }
                description={
                  <Row justify='end' className='hp-mt-32'>
                    <Col>
                      <Row>
                        <IntlMessages id="features-edit-publish-to-hc" />
                        <Switch name="is_published" className='hp-ml-16' />
                      </Row>
                    </Col>
                  </Row>
                }
                type="info"
                showIcon
              />
            )}

            {isEdit && !hasReleasedImages && (
              <Alert
                className='hp-mb-32'
                message={<IntlMessages id="bonus-publish-notification-note" />}
                type="warning"
                showIcon
              />
            )}

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <ResetButton disabled={false} loading={isSubmitting}>
                  <IntlMessages id='ui-general-reset' />
                </ResetButton>
              </Col>

              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id="ui-general-save" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditBonus.propTypes = {
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  hasReleasedImages: PropTypes.bool,
};

export default FormCreateEditBonus;
