import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, Col, Row, Image, Tooltip, DatePicker, Typography, Collapse, Alert, Divider, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton, Switch } from 'formik-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import appColors from '../../../../const/colors';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import getFeatureConfiguration from '../../utils/getFeatureConfiguration';
import isDateDisabled from '../../../../lib/isDateDisabled';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../const/system';
import { DEFAULT_RELEASE_TIME } from './FormEditFeature.const';
import { FEATURES_SOURCE_MAP } from '../../pages/PageFeatures/PageFeatures.const';
import DynamicFormDataFields from '../../../Content/components/DynamicFormDataFields';
import TargetingSettings from '../../../../components/TargetingSettings';
import TitleWithAutomaticGeneration from '../../../../components/TitleWithAutomaticGeneration';
import mkTemplateDynamicFormData from '../../../Content/utils/mkTemplateDynamicFormData';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import getFeatureSourceTranslation from '../../utils/getFeatureSourceTranslation';
import mkMainAndOptionalTemplateFields from '../../../Template builder/utils/mkMainAndOptionalTemplateFields';
import mkTemplatesOptions from '../../../Content/utils/mkTemplatesOptions';
import PresetPreview from '../../../../components/PresetPreview';
import applyPreset from '../../../../lib/applyPreset';
import { useGetPresetsQuery } from '../../../Template builder/api/presetsApiSlice';



const { Title } = Typography;
const { Panel } = Collapse;

const FormEditFeature = ({
  isLoading,
  images,
  templateFields,
  isSubmitting,
  initialErrors,
  initialValues,
  validationSchema,
  onSubmit,
  unavailableDates,
  isEnabledTemplateStatus = false,
  canPublish = false,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();
  const location = useLocation();
  const { pathname } = location;
  const featureSource = getSourceFromUrl(pathname);
  const featureConfig = getFeatureConfiguration(featureSource);

  const {
    data: { data: presets } = { data: [] },
  } = useGetPresetsQuery({
    queryParams: `search=template_id:${initialValues?.template_id}`,
  }, { skip: !initialValues?.template_id });

  const dateCellRender = (current, released_at) => {
    const style = {};

    if (moment(released_at, DATE_FORMAT).isSame(current.format(DATE_FORMAT))) {
      style.background = appColors.smokeGray;
      style.color = appColors.darkGray;
    } else if (unavailableDates.includes(current.format(DATE_FORMAT))) {
      style.background = appColors.smokeGray;
      style.borderRadius = 0;
    }

    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  const useDatePickerTime = featureConfig?.config?.publish?.use_time ?? false;
  const defaultConfigUtcValue = featureConfig?.config?.publish?.enabled_utc0_field ?? false;
  const isDisabledUtcField = !featureConfig?.config?.publish?.show_utc0_field;
  const hasReleaseRange = featureConfig?.config?.release_range ?? false;
  const useAutomaticGeneration = featureConfig?.config?.title?.use_automatic_generation ?? true;
  const { mainFields, optionalFields } = mkMainAndOptionalTemplateFields(templateFields);

  return (
    <Col span={24}>
      <Card loading={isLoading} className="hp-border-color-black-40 hp-card-6">
        <Row gutter={[ 32, 32 ]}>
          {!isEmpty(images) && (
            <Col sm={24} md={4}>
              <Row gutter={[ 16, 16 ]}>
                {images.map((image) => {
                  return (
                    <Col key={image?.id} span={24}>
                      <Row>
                        <Col span={24}>
                          <Image src={image.url} />
                        </Col>
                        {featureSource === FEATURES_SOURCE_MAP.EDITOR_CHOICE && (
                          <Col span={24} className='hp-align-items-center'>
                            <span style={{ fontSize: '14px', fontWeight: 500 }}>
                              {`${intl.formatMessage({ id: 'ui-general-published-at' })}: ${image?.release_date}`}
                            </span>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          )}

          <Col sm={24} md={!isEmpty(images) ? 20 : 24}>
            <Formik
              enableReinitialize
              isSubmitting={isSubmitting}
              initialErrors={initialErrors}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                await onSubmit(mkTemplateDynamicFormData(values, templateFields), resetForm);
              }}
            >
              {({ values, setFieldValue, isValid, dirty, setFieldTouched, resetForm }) => {
                const hasUnpublishedChanges = !moment(values?.published_at).isSame(moment(values?.updated_at));
                const preset = presets?.find((preset) => Number(preset.id) === Number(values?.preset_id));

                if (!canPublish && values?.is_published) {
                  setFieldValue('is_published', false);
                }

                return (
                  <Form layout="vertical" className='w-full'>
                    <Title className="hp-mb-24" level={4}>
                      {intl.formatMessage({ id: getFeatureSourceTranslation(featureSource) })}
                    </Title>

                    <Row gutter={16}>
                      <Col md={12} xs={24}>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Row gutter={8}>
                              <Col span={24}>
                                <FormItem
                                  label={intl.formatMessage({ id: 'ui-general-released-at' })}
                                  name='released_at'
                                  required
                                  className="hp-mb-8"
                                >
                                  <DatePicker
                                    name='released_at'
                                    showToday
                                    showTime={useDatePickerTime
                                      ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT).utc(true) }
                                      : false}
                                    disabledDate={(date) => isDateDisabled(
                                      date,
                                      unavailableDates,
                                      !isEmpty(values?.released_to)
                                        ? date.isAfter(moment(values?.released_to))
                                        : false,
                                    )}
                                    value={values?.released_at ? moment(values.released_at).utc(false) : null}
                                    onChange={async (date, dateString) => {
                                      await setFieldValue('released_at', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                                    }}
                                    onBlur={async ({ target }) => {
                                      await setFieldTouched('released_at', true);
                                      await onBlur('released_at', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                                    }}
                                    dateRender={(current) => dateCellRender(current, initialValues?.released_at)}
                                  />
                                </FormItem>
                              </Col>
                              {hasReleaseRange && (
                                <Col span={24}>
                                  <FormItem
                                    label={intl.formatMessage({ id: 'ui-general-released-to' })}
                                    required
                                    name='released_to'
                                  >
                                    <DatePicker
                                      name='released_to'
                                      showTime={useDatePickerTime
                                        ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT) }
                                        : false}
                                      disabledDate={(date) => isDateDisabled(
                                        date,
                                        [],
                                        !isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at)),
                                      )}
                                      value={values?.released_to ? moment(values.released_to).utc(false) : null}
                                      onChange={async (date, dateString) => {
                                        await setFieldValue('released_to', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                                      }}
                                      onBlur={async ({ target }) => {
                                        await setFieldTouched('released_to', true);
                                        await onBlur('released_to', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                                      }}
                                    />
                                  </FormItem>
                                </Col>
                              )}
                            </Row>
                          </Col>
                          <Col span={24}>
                            {featureConfig?.config?.show_in_featured_enabled && (
                              <FormItem
                                name='is_in_featured'
                                label={
                                  <Row className="w-full" justify="space-between" align="middle">
                                    <IntlMessages id="features-form-show-featured-label" />
                                    <Tooltip placement="top" title={<IntlMessages id="features-show-in-features-info" />}>
                                      <InfoCircleOutlined className='hp-ml-8' />
                                    </Tooltip>
                                  </Row>
                                }
                              >
                                <Switch name="is_in_featured" />
                              </FormItem>
                            )}
                          </Col>

                          <Col span={24}>
                            <FormItem
                              label={intl.formatMessage({ id: 'ui-general-preset' })}
                              extra={intl.formatMessage({ id: 'presets-template-hint' })}
                              name='preset_id'
                            >
                              <Select
                                loading={false}
                                allowClear
                                name="preset_id"
                                onChange={async (value) => {
                                  await applyPreset(value, values, setFieldValue, presets, templateFields);
                                }}
                                options={mkTemplatesOptions(presets)}
                                placeholder={intl.formatMessage({ id: 'ui-general-choose-preset' })}
                              />
                            </FormItem>
                          </Col>
                          <Col span={24}>
                            <Collapse accordion className='hp-mb-24'>
                              <Panel
                                key="additional-info"
                                header={
                                  <Title className='collapse-item-title' level={5}>
                                    {intl.formatMessage({ id: 'ui-general-optional-data' })}
                                  </Title>
                                }
                              >
                                <Row gutter={16}>
                                  <Col span={24}>
                                    <Row gutter={16}>
                                      <Col span={24}>
                                        <TitleWithAutomaticGeneration
                                          rows={2}
                                          useAutomaticGeneration={useAutomaticGeneration}
                                        />
                                      </Col>
                                      <Col span={24}>
                                        <FormItem
                                          name='use_utc_zero_time'
                                          label={
                                            <Row className='w-full' justify="space-between" align="middle">
                                              <IntlMessages id="features-use-utc" />
                                              <Tooltip
                                                placement="top"
                                                title={
                                                  <>
                                                    <IntlMessages id="features-use-utc-info" />
                                                    <div>
                                                      <Link to='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/734461960/Use+UTC+0' target="_blank">
                                                        <u><IntlMessages id="ui-general-documentation-link" /></u>
                                                      </Link>
                                                    </div>
                                                  </>
                                                }
                                              >
                                                <InfoCircleOutlined className='hp-ml-8' />
                                              </Tooltip>
                                            </Row>
                                          }
                                        >
                                          <Switch
                                            name="use_utc_zero_time"
                                            defaultChecked={defaultConfigUtcValue}
                                            disabled={isDisabledUtcField}
                                          />
                                        </FormItem>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={24}>
                                    <FormItem
                                      className='ant-form-item-col'
                                      name="description"
                                      label={<IntlMessages id="ui-general-description" />}
                                    >
                                      <Input.TextArea
                                        name='description'
                                        placeholder={intl.formatMessage({ id: 'ui-general-specify-value' })}
                                        rows={2}
                                        showCount
                                        maxLength={1000}
                                      />
                                    </FormItem>
                                  </Col>
                                </Row>
                              </Panel>
                            </Collapse>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} xs={24}>
                        <PresetPreview src={preset?.preview} />
                      </Col>
                    </Row>

                    <Divider />

                    <Title className="hp-mb-24" level={4}><IntlMessages id="ui-general-template-data" /></Title>

                    {templateFields.length ? (
                      <Row>
                        <Col span={12}>
                          <DynamicFormDataFields
                            setFieldValue={setFieldValue}
                            fields={mainFields}
                            values={values}
                            span={24}
                          />
                          {!!optionalFields.length && (
                            <Collapse>
                              <Panel
                                key="1"
                                header={
                                  <Title level={5} className='collapse-item-title'>
                                    <IntlMessages id="ui-general-additional-parameters" />
                                  </Title>
                                }
                              >
                                <DynamicFormDataFields
                                  setFieldValue={setFieldValue}
                                  fields={optionalFields}
                                  values={values}
                                  span={24}
                                />
                              </Panel>
                            </Collapse>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <Row gutter={16} className="hp-d-flex-justify-center"><Spin spinning /></Row>
                    )}

                    <Divider />

                    <TargetingSettings setFieldValue={setFieldValue} values={values} />

                    {canPublish && (dirty || hasUnpublishedChanges) && (
                      <Alert
                        message={<IntlMessages id="features-edit-has-changes" />}
                        description={
                          <>
                            <Row justify='end' className='hp-mt-32'>
                              <Col>
                                <Row>
                                  <IntlMessages id="features-edit-publish-to-hc" />
                                  <Switch disabled={!isEnabledTemplateStatus} name="is_published" className='hp-ml-16' />
                                </Row>
                              </Col>
                            </Row>
                            {!isEnabledTemplateStatus && (
                              <Row>
                                <div className='error-text'>
                                  <IntlMessages id='features-disabled-template-text-error' />
                                </div>
                              </Row>
                            )}
                          </>
                        }
                        type="info"
                        showIcon
                      />
                    )}

                    {!canPublish && (
                      <Alert
                        className='hp-mb-32'
                        message={<IntlMessages id="features-publish-notification-note" />}
                        type="warning"
                        showIcon
                      />
                    )}

                    <Row gutter={[ 16, 16 ]} className='hp-mt-16' justify='end'>
                      <Col>
                        <ResetButton
                          disabled={false}
                          onClick={() => {
                            resetForm(initialValues);
                          }}
                        >
                          <IntlMessages id='ui-general-reset' />
                        </ResetButton>
                      </Col>

                      <Col>
                        <SubmitButton
                          type="primary"
                          loading={isSubmitting}
                          disabled={!isValid || !dirty}
                        >
                          <IntlMessages id="ui-general-save" />
                        </SubmitButton>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

FormEditFeature.propTypes = {
  images: PropTypes.array.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  templateFields: PropTypes.array.isRequired,
  unavailableDates: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEnabledTemplateStatus: PropTypes.bool,
  canPublish: PropTypes.bool,
};

export default FormEditFeature;
