import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const ImagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getCompressedCargo: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/compressed/cargo${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
      }),
      getImages: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/images${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Images' ],
      }),
      attachImage: builder.mutation({
        query: ({ id, payload }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}/attachments`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: () => [ 'Images', 'Achievements', 'Achievement', 'Bonuses', 'BonusesItem', 'Collections', 'CollectionsItem' ],
      }),
      copyImage: builder.mutation({
        query: ({ id }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}/copies`,
            method: 'POST',
          };
        },
        invalidatesTags: () => [ 'Images' ],
      }),
      updateImage: builder.mutation({
        query: ({ id, payload }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}`,
            method: 'PATCH',
            body: payload,
          };
        },
        // DO NOT INVALIDATE 'Feeds' HERE
        // feeds/planning query is used on planning details page where we use context + updates images without reloading whole list.
        // Invalidate feeds after updating image cause bugs with images data on lists
        invalidatesTags: () => [ 'Images', 'Categories', 'ImagesItem' ],
      }),
      updateImageStatus: builder.mutation({
        query: ({ id, status }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}/status/${status}`,
            method: 'POST',
          };
        },
        invalidatesTags: () => [ 'Images', 'ImagesItem', 'FeedsPlanning', 'FeedsPlanningDetails' ],
      }),
      reopenImage: builder.mutation({
        query: ({ id }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}/state`,
            method: 'POST',
          };
        },
        invalidatesTags: () => [ 'Images' ],
      }),
    });
  },
});

export const {
  useGetCompressedCargoQuery,
  useGetImagesQuery,
  useAttachImageMutation,
  useCopyImageMutation,
  useReopenImageMutation,
  useUpdateImageMutation,
  useUpdateImageStatusMutation,
} = ImagesApiSlice;
