import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Link, useSearchParams } from 'react-router-dom';
import urlPageNews from '../../../../../urls/urlPageNews';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import useCheckMobileScreen from '../../../../../app/hooks/useCheckMobileScreen';
import applyFilter from '../../../../../lib/applyFilter';
import handleResponse from '../../../../../lib/handleResponse';
import Spinner from '../../../../../components/Spinner';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import PrioritiesTitle from '../../../../../components/PrioritiesTitle';
import PriorityFilter from '../../../../../components/PriorityFilter';
import NewsPriorityList from '../../../components/NewsPriorityList';
import { Permissions } from '../../../../../const/permissions';
import { DATE_FORMAT } from '../../../../../const/system';
import { useGetNewsPriorityQuery, useUpdateNewsPriorityMutation } from '../../../api/newsApiSlice';



const PageNewsPriority = () => {
  const intl = useIntl();
  const [ newsOrder, setNewsOrder ] = useState([]);
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const searchFilterFields = [ 'date' ];
  const isMobile = useCheckMobileScreen();

  const { data: news = { data: [], pagination: {} }, isFetching } = useGetNewsPriorityQuery({
    queryParams: searchQueryParams.get('search') ? `search=${searchQueryParams.get('search')}` : '',
  });

  const [ updateNewsPriority, { isLoading: isUpdatingNewsPriority } ] = useUpdateNewsPriorityMutation();

  const today = dayjs().format(DATE_FORMAT);
  const search = searchQueryParams.get('search');
  const date = search ? search.split(':')[1] : today;


  const onNewsOrderChange = (order) => {
    setNewsOrder(order);
  };

  const submitNewsPriority = () => {
    const order = newsOrder.map((item) => {
      const position = Number(item) - 1;

      return news.data[position]?.id;
    });

    updateNewsPriority({ order, date })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-updated-successfully'))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [
          () => setNewsOrder([]),
        ],
        error,
      ));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageNews()}>
              <IntlMessages id='news-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-priority' />}
        />
      </Row>

      <Row gutter={[ 16, 16 ]} style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
        <Col sm={24} md={16}>
          <Card
            className="hp-border-color-black-40 hp-card-6"
            title={(
              <PrioritiesTitle
                isDisabled={!newsOrder.length}
                hasPermission={HasRights([ Permissions.CONTENT.NEWS.PRIORITY ])}
                title={intl.formatMessage({ id: 'news-priority-title' })}
                onAction={submitNewsPriority}
              />
            )}
          >
            <Spinner spinning={isFetching || isUpdatingNewsPriority}>
              <NewsPriorityList
                data={news.data}
                isLoading={isFetching || isUpdatingNewsPriority}
                onOrderChange={onNewsOrderChange}
              />
            </Spinner>
          </Card>
        </Col>
        <Col sm={24} md={8}>
          <Card
            title={intl.formatMessage({ id: 'news-priority-filter-title' })}
            className="hp-border-color-black-40 hp-card-6"
          >
            <PriorityFilter
              initialValues={searchQueryParams.get('search') ? { date } : {}}
              onCancel={() => {}}
              isSubmitting={isFetching || isUpdatingNewsPriority}
              onSubmit={(values) => {
                applyFilter({
                  values,
                  searchQueryParams,
                  searchFilterFields,
                  setSearchParams,
                  callbacks: [ () => setNewsOrder([]) ],
                });
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageNewsPriority;
