const uniqueById = (array) => {
  return array.reduce((accumulator, currentObject) => {
    const index = accumulator.findIndex((obj) => obj.id === currentObject.id);

    if (index !== -1) {
      accumulator.splice(index, 1);
    }

    accumulator.push(currentObject);

    return accumulator;
  }, []);
};

export default uniqueById;
