import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const FeedsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getFeeds: builder.query({
        query: (params) => {
          const { feedSource, queryParams } = params;
          const queryData = makeQueryParams(queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/feeds/${feedSource}${queryData}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: {
              has_more: response.meta.custom.pagination.has_more,
              current_page: response.meta.custom.pagination.current_page,
              total: response.meta.custom.pagination.total,
            },
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Feeds' ],
        keepUnusedDataFor: 1,
      }),
    });
  },
});

export const { useGetFeedsQuery } = FeedsApiSlice;
