import React from 'react';
import { Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { PiDotsThreeOutlineFill } from 'react-icons/pi';
import { TfiCommentAlt } from 'react-icons/tfi';
import appColors from '../../../../const/colors';
import { getLabelsColors } from './ContentCardTags.const';
import CollapsibleText from '../../../../components/CollapsibleText';



const ContentCardTags = ({ comment = '', color = 'default', items = [], cardSize, isLabel = false }) => {
  const allowedLabelsCount = cardSize ? Math.floor(cardSize.width / 90) : 0;
  const hasCollapsedLabels = items.length > allowedLabelsCount;

  if (!items.length && !comment) {
    return null;
  }

  return (
    <div>
      <div className={isLabel ? 'content-labels-section' : 'content-tags-section'}>
        {items.slice(0, allowedLabelsCount).map((item) => {
          return (
            <Tag key={item} color={isLabel ? getLabelsColors(item) : color}>
              <CollapsibleText text={capitalize(item)} maxLength='30' />
            </Tag>
          );
        })}
        <div
          className='hp-align-self-center hp-cursor-pointer'
          style={{ width: '20px', height: '22px' }}
        >
          {hasCollapsedLabels && (
            <Tooltip
              placement="top"
              title={items.map((item) => {
                return (
                  <div key={item}>{capitalize(item)}</div>
                );
              })}
            >
              <PiDotsThreeOutlineFill size='18' color={appColors.white} />
            </Tooltip>
          )}
        </div>
      </div>

      <div className='content-comment'>
        {comment && (
          <Tooltip placement="top" title={comment}>
            <div className='hp-d-flex hp-align-items-center hp-cursor-pointer'>
              <TfiCommentAlt size={16} fill={appColors.white} stroke={appColors.white} strokeWidth='1' />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

ContentCardTags.propTypes = {
  color: PropTypes.string,
  comment: PropTypes.string,
  cardSize: PropTypes.object,
  isLabel: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ContentCardTags;
