import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import themeConfig from '../../../configs/themeConfig';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';



const customiseData = JSON.parse(localStorage.getItem('app_config')) || {};

dayjs.extend(updateLocale);
dayjs.locale(customiseData?.language || themeConfig.language);

const initialState = {
  theme: customiseData?.theme || themeConfig.theme,
  contentWidth: customiseData?.contentWidth || themeConfig.contentWidth,
  sidebarCollapsed: customiseData?.sidebarCollapsed || themeConfig.sidebarCollapsed,
  sidebarCollapseButton: customiseData?.sidebarCollapseButton || themeConfig.sidebarCollapseButton,
  layout: customiseData?.layout || themeConfig.layout,
  navigationFull: customiseData?.navigationFull || themeConfig.navigationFull,
  navigationBg: customiseData?.navigationBg || themeConfig.navigationBg,
  direction: customiseData?.direction || themeConfig.direction,
  language: customiseData?.language || themeConfig.language,
};

export const customiseSlice = createSlice({
  name: 'customise',
  initialState,
  reducers: {
    theme: (state, action) => {
      state.theme = action.payload;
      customiseData.theme = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
    },
    contentWidth: (state, action) => {
      state.contentWidth = action.payload;
      customiseData.contentWidth = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
    },
    sidebarCollapsed: (state, action) => {
      state.sidebarCollapsed = action.payload;
      customiseData.sidebarCollapsed = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
    },
    sidebarCollapseButton: (state, action) => {
      state.sidebarCollapseButton = action.payload;
      customiseData.sidebarCollapseButton = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
    },
    layoutChange: (state, action) => {
      state.layout = action.payload;
      customiseData.layout = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
    },
    navigationFull: (state, action) => {
      state.navigationFull = action.payload;
      customiseData.navigationFull = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
    },
    navigationBg: (state, action) => {
      state.navigationBg = action.payload;
      customiseData.navigationBg = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
    },
    direction: (state, action) => {
      state.direction = action.payload;
      customiseData.direction = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
    },
    switchLanguage: (state, action) => {
      state.language = action.payload;
      customiseData.language = action.payload;
      localStorage.setItem('app_config', JSON.stringify(customiseData));
      dayjs.updateLocale(action.payload, {});
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  theme,
  contentWidth,
  sidebarCollapsed,
  sidebarCollapseButton,
  navigationBg,
  navigationFull,
  direction,
  switchLanguage,
} = customiseSlice.actions;

export default customiseSlice.reducer;
