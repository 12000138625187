import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useSearchParams } from 'react-router-dom';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import useToggle from '../../../../../app/hooks/useToggle';
import useInfiniteScroll from '../../../../../app/hooks/useInfiniteScroll';
import getSourceFromUrl from '../../../../../lib/getSourceFromUrl';
import ButtonDanger from '../../../../../components/ButtonDanger';
import FeedsLayout from '../../../components/FeedsLayout';
import {
  FEEDS_SOURCE_MAP,
  searchFilterFields,
  MODAL_MODE,
} from '../PageFeeds.const';
import { useGetFeedsQuery } from '../../../api/feedsApiSlice';



const PageFeedsArchiveAndTrash = () => {
  const { pathname } = useLocation();
  const feedSource = getSourceFromUrl(pathname);
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ showContour, toggleShowContour ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ queryParams, setQuery ] = useState('');
  const [ modalMode, setModalMode ] = useState(MODAL_MODE.VIEW);
  const [ isUpdateList, setIsUpdateList ] = useState(false);
  const [ updatedListItem, setUpdatedListItem ] = useState({});

  const { data: {
    data,
    pagination,
  } = { data: [] },
  isFetching,
  error: formErrors = {},
  } = useGetFeedsQuery({ feedSource, queryParams });

  const {
    itemsArray,
    groupedItemsList,
    itemsLength,
    hasMore,
    handleLoadNextData,
    initFilterValues,
    setInitFilterValues,
    combinedQueryParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  } = useInfiniteScroll({
    data,
    pagination,
    searchFilterFields,
    source: feedSource,
    isUpdateList,
    updatedListItem,
  });

  useEffect(() => {
    setQuery(!isEmpty(combinedQueryParams) ? combinedQueryParams : '');
  }, [ combinedQueryParams ]);

  const handleUpdateListItem = (id, values = {}) => {
    setIsUpdateList(true);
    setUpdatedListItem({ id, values });
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  const handleChangeModalMode = (mode) => {
    setModalMode(mode);
  };

  const actions = (item) => {
    const permission = feedSource === FEEDS_SOURCE_MAP.ARCHIVED
      ? [ Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED ]
      : [ Permissions.CONTENT.FEEDS.IMAGE.SET_DELETE ];

    return [
      HasRights(permission) && (
        <ButtonDanger
          className='btn-danger'
          key='remove'
          text={<IntlMessages id='ui-general-remove' />}
          onClick={(event) => {
            event.target?.blur();
            setPreviewImage(item);
            setModalMode(MODAL_MODE.REMOVE);
            togglePreviewModal();
          }}
        />
      ),
    ];
  };

  return (
    <FeedsLayout
      feedSource={feedSource}
      modalMode={modalMode}
      previewModalOpen={previewModalOpen}
      handlePreviewModalClose={handlePreviewModalClose}
      previewImage={previewImage}
      handleUpdateListItem={handleUpdateListItem}
      handleChangeModalMode={handleChangeModalMode}
      formErrors={formErrors}
      initFilterValues={initFilterValues}
      searchQueryParams={searchQueryParams}
      setSearchParams={setSearchParams}
      setInitFilterValues={setInitFilterValues}
      setResetManuallyTriggered={setResetManuallyTriggered}
      resetIsManuallyTriggered={resetIsManuallyTriggered}
      isLoading={isFetching}
      groupedItemsList={groupedItemsList}
      itemsLength={itemsLength}
      itemsArray={itemsArray}
      handleLoadNextData={handleLoadNextData}
      hasMore={hasMore}
      actions={actions}
      handleCardClick={handleCardClick}
      showContour={showContour}
      toggleShowContour={toggleShowContour}
      isVisibleFilterSidebar={isVisibleFilterSidebar}
      toggleFilterSidebar={toggleFilterSidebar}
    />
  );
};

export default PageFeedsArchiveAndTrash;
