const imageLabels = {
  facebook: 'fb',
  achievement: 'achv',
  blend: 'blend',
  hidden: 'hidden',
  mystery: 'myst',
  cargo: 'cargo',
  aspca: 'aspca',
  battersea: 'battersea',
  make_a_wish: 'make-a-wish',
};

export { imageLabels };
