import React, { useMemo } from 'react';
import { Badge, Col, Row, Tooltip } from 'antd';
import { RiBarChartFill, RiQuestionLine } from 'react-icons/ri';
import { IoMdImages } from 'react-icons/io';
import { PiWaveSineDuotone } from 'react-icons/pi';
import appColors from '../../../../const/colors';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { usePlanningBoardImagesContext } from '../../contexts/PlanningImagesContext';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const PlanningBoardDetailsHeader = () => {
  const { items: planningBoardImages } = usePlanningBoardImagesContext();

  const { medianTaps, averageTaps, imagesCount } = useMemo(() => {
    if (!planningBoardImages || planningBoardImages.length === 0) {
      return { medianTaps: 0, averageTaps: 0, imagesCount: 0 };
    }

    const taps = planningBoardImages?.map((item) => item.data.data[0].taps);

    const average = Math.round(taps.reduce((sum, tap) => sum + tap, 0) / taps.length);

    taps.sort((first, second) => first - second);
    const mid = Math.floor(taps.length / 2);
    const median = Math.round(taps.length % 2 !== 0 ? taps[mid] : (taps[mid - 1] + taps[mid]) / 2);

    return { medianTaps: median, averageTaps: average, imagesCount: planningBoardImages.length };
  }, [ planningBoardImages ]);

  return (
    <Row
      gutter={[ 8, 8 ]}
      justify='space-between'
      className='hp-align-items-center'
    >
      <Col span={24}>
        <Row justify='start' className='hp-align-items-center'>
          <IntlMessages id="ui-general-planning-images" />
          <Tooltip
            placement="top"
            title={
              <>
                <b><IntlMessages id="planning-board-status-info" /></b>
                <br />
                <Badge color='volcano' />
                <IntlMessages id="planning-board-status-info-red" />
                <br />
                <br />
                <Badge color='gold' />
                <IntlMessages id="planning-board-status-info-yellow" />
                <br />
                <br />
                <Badge color='lime' />
                <IntlMessages id="planning-board-status-info-green" />
              </>
            }
          >
            <RiQuestionLine
              size={18}
              fill='blue'
              className='hp-ml-8 hp-cursor-pointer'
            />
          </Tooltip>
        </Row>
      </Col>
      <Col span={24}>
        <Row
          justify='start'
          gutter={16}
          className='hp-align-items-center'
          style={{ fontSize: 14 }}
        >
          <Col>
            <Row className='hp-align-items-center'>
              <IoMdImages size={26} className='hp-text-color-black-80' />
            </Row>
          </Col>
          <Col
            style={{
              color: imagesCount < 15 ? appColors.error : '',
              fontSize: 18,
              paddingLeft: 0,
            }}
          >
            <strong>{imagesCount}</strong>
          </Col>
          <Col className='hp-text-color-black-80 hp-ml-8'>
            <Tooltip placement="top" title={<IntlMessages id="feeds-calendar-average" />}>
              <Row className='hp-align-items-center'>
                <PiWaveSineDuotone size='18' className='hp-mr-4' />
                {averageTaps ?? EMPTY_VALUE_PLACEHOLDER}
              </Row>
            </Tooltip>
          </Col>
          <Col className='hp-text-color-black-80'>
            <Tooltip placement="top" title={<IntlMessages id="feeds-calendar-median" />}>
              <Row className='hp-align-items-center'>
                <RiBarChartFill size='18' className='hp-mr-4' />
                {medianTaps ?? EMPTY_VALUE_PLACEHOLDER}
              </Row>
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PlanningBoardDetailsHeader;
