import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, DatePicker, Row, Select, Space, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RiInformationLine } from 'react-icons/ri';
import { Form, FormItem } from 'formik-antd';
import { Formik } from 'formik';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { useSearchParams } from 'react-router-dom';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { DATE_FORMAT } from '../../../../const/system';
import getQueryParams from '../../../../lib/getQueryParams';
import decodeUnsafeCharacters from '../../../../lib/decodeUnsafeCharacters';
import FeedsPlanningBoardCalendarCells from './FeedsPlanningBoardCalendarCells';
import {
  defaultDateFrom,
  defaultDateTo,
  defaultInitValues,
  monthOptions,
  validationSchema,
  yearOptions,
} from './FeedsPlanningBoardCalendar.const';
import { useGetFeedsPlanningBoardQuery } from '../../api/feedsPlanningApiSlice';



const { RangePicker } = DatePicker;

const FeedsPlanningBoardCalendar = () => {
  const customise = useSelector((state) => state.customise);
  const initialQueryParams = getQueryParams(window.location.search);
  const [ , setSearchParams ] = useSearchParams();

  const getDefaultDates = () => {
    if (initialQueryParams.planningSearch) {
      const values = {};

      decodeUnsafeCharacters(initialQueryParams.planningSearch).split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        values[key] = value;
      });
      return values;
    }

    return {
      date_from: defaultDateFrom,
      date_to: defaultDateTo,
    };
  };

  const getDefaultInitialValues = () => {
    if (initialQueryParams.planningSearch) {
      const values = {};

      decodeUnsafeCharacters(initialQueryParams.planningSearch).split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        values[key] = value;
      });
      return {
        range: [ moment(values.date_from), moment(values.date_to) ],
        month: `${moment(values.date_from).format('MM')}-01`,
        year: moment(values.date_from).format('YYYY'),
      };
    }
    return defaultInitValues;
  };

  const [ dates, setDates ] = useState(getDefaultDates());

  useEffect(() => {
    if (!initialQueryParams.planningSearch) {
      setSearchParams({
        ...initialQueryParams,
        planningSearch: `date_from%3A${defaultDateFrom}%3Bdate_to%3A${defaultDateTo}`,
      }, { replace: true });
    }
  }, [ initialQueryParams, setSearchParams ]);


  const { data: { data = [] } = {}, isFetching } = useGetFeedsPlanningBoardQuery({
    queryParams: `search=date_from%3A${dates.date_from}%3Bdate_to%3A${dates.date_to}`,
  }, { skip: !dates.date_from && !dates.date_to });

  const cardsData = useMemo(() => {
    const startDate = dayjs(dates.date_from);
    const endDate = dayjs(dates.date_to);
    const dataMap = new Map(data.map((item) => [ item.date, item ]));

    const result = [];

    // eslint-disable-next-line fp/no-loops
    for (
      let currentDate = startDate;
      currentDate.isBefore(endDate) || currentDate.isSame(endDate);
      currentDate = currentDate.add(1, 'day')
    ) {
      const dateStr = currentDate.format(DATE_FORMAT);

      result.push(dataMap.get(dateStr) || { date: dateStr });
    }

    return result;
  }, [ data ]);

  const cardTitle = useMemo(() => {
    let title = <IntlMessages id="feeds-planning-board" />;

    if (dates.date_from) {
      title = dayjs(dates.date_from).format('MMMM YYYY');
      title = title.charAt(0).toUpperCase() + title.slice(1);
    }

    return (
      <Row justify='space-between'>
        <Col>{title}</Col>
        <Col>
          <Tooltip
            className='documentation-tooltip'
            placement="top"
            title={<IntlMessages id='feeds-calendar-filter-info' />}
          >
            <RiInformationLine size={20} fill='blue' className='hp-ml-4 hp-cursor-pointer' />
          </Tooltip>
        </Col>
      </Row>
    );
  }, [ dates.date_from, customise.language ]);

  const handleSetDates = (dateFromValue, dateToValue) => {
    setDates({
      date_from: dateFromValue,
      date_to: dateToValue,
    });

    setSearchParams({
      ...initialQueryParams,
      planningSearch: `date_from%3A${dateFromValue}%3Bdate_to%3A${dateToValue}`,
    }, { replace: true });
  };

  const onRangeDatesChange = (value, setFieldValue) => {
    let dateFromValue = defaultDateFrom;

    let dateToValue = defaultDateTo;

    if (value && value[0] && value[1]) {
      dateFromValue = value[0].format(DATE_FORMAT);
      dateToValue = value[1].format(DATE_FORMAT);
    }

    handleSetDates(dateFromValue, dateToValue);
    setFieldValue('range', value, true);
  };

  const onMonthDateChange = (values, value, setFieldValue) => {
    const dateFromValue = `${values.year}-${value}`;
    const dateToValue = dayjs(`${values.year}-${value}`).endOf('month').format(DATE_FORMAT);

    handleSetDates(dateFromValue, dateToValue);
    setFieldValue('month', value, true);
  };

  const onYearDateChange = (values, value, setFieldValue) => {
    const dateFromValue = `${value.toString()}-${values.month}`;
    const dateToValue = dayjs(`${value.toString()}-${values.month}`).endOf('month').format(DATE_FORMAT);

    handleSetDates(dateFromValue, dateToValue);
    setFieldValue('year', value, true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getDefaultInitialValues()}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Spin spinning={isFetching}>
              <Card title={cardTitle}>
                <Row
                  gutter={[ 16, 16 ]}
                  justify="end"
                  align="middle"
                  className='hp-pr-4 hp-pl-4'
                >
                  <Col>
                    <Space>
                      <FormItem name='range'>
                        <RangePicker
                          name='range'
                          value={!isEmpty(values) && values.range ? values.range : []}
                          onCalendarChange={(value) => onRangeDatesChange(value, setFieldValue)}
                          disabledDate={(date) => {
                            if (!values.range) {
                              return false;
                            }

                            return moment(date).diff(values.range[0], 'days') > 30 || moment(values.range[1]).diff(date, 'days') > 30;
                          }}
                        />
                      </FormItem>

                      <FormItem name='month'>
                        <Select
                          value={!isEmpty(values) && values.month ? values.month : null}
                          name='month'
                          style={{ width: 85 }}
                          onSelect={(value) => onMonthDateChange(values, value, setFieldValue)}
                          options={monthOptions}
                        />
                      </FormItem>

                      <FormItem name='year'>
                        <Select
                          value={!isEmpty(values) && values.year ? values.year : null}
                          name='year'
                          style={{ width: 90 }}
                          onChange={(value) => onYearDateChange(values, value, setFieldValue)}
                          options={yearOptions()}
                        />
                      </FormItem>
                    </Space>
                  </Col>
                </Row>

                <FeedsPlanningBoardCalendarCells data={cardsData} />
              </Card>
            </Spin>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FeedsPlanningBoardCalendar;
