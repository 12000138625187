import capitalize from 'lodash/capitalize';



const imageTypes = {
  sharp: 'sharp',
  cargo: 'cargo',
  blend: 'blend',
  chop: 'chop',
  bee_chop: 'bee chop',
  rare: 'rare', // Он же Cargo (alias)
};

const imageTypeOptions = Object.entries(imageTypes)
  .map(([ key, value ]) => {
    return { label: capitalize(value), value: key };
  });


export { imageTypes, imageTypeOptions };
