import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const CategoriesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Categories', 'CategoriesPriorities' ],
  endpoints: (builder) => {
    return ({
      getCategories: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/categories${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Categories' ],
      }),
      getCategory: builder.query({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/categories/${id}?include=dictionary`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          return {
            data: response?.data,
          };
        },
        providesTags: [ 'Categories' ],
      }),
      createCategory: builder.mutation({
        query: ({ payload }) => {
          return ({
            url: `/${Config.VITE_HC_API_VERSION}/categories`,
            method: 'POST',
            body: payload,
          });
        },
        invalidatesTags: () => [ 'Categories', 'Structures', 'Dictionaries' ],
      }),
      updateCategory: builder.mutation({
        query: ({ id, payload }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/categories/${id}`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: () => [ 'Categories' ],
      }),
      deleteCategory: builder.mutation({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/categories/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'Categories' ],
      }),
      getCategoriesPriorities: builder.query({
        query: () => {
          return ({ url: `/${Config.VITE_HC_API_VERSION}/categories/settings/priorities` });
        },
        transformResponse: (response) => {
          return response.data;
        },
        providesTags: [ 'CategoriesPriorities', 'Categories' ],
      }),
      updateCategoriesPriority: builder.mutation({
        query: (payload) => ({
          url: `/${Config.VITE_HC_API_VERSION}/categories/settings/priorities`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: () => [ 'Categories', 'CategoriesPriorities' ],
      }),
    });
  },
});

export const {
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useDeleteCategoryMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoriesPrioritiesQuery,
  useUpdateCategoriesPriorityMutation,
} = CategoriesApiSlice;
