import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import dayjs from 'dayjs';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import appColors from '../../../../const/colors';



const PlanningBoardDetailsBadge = ({ date, children, isEditing = false }) => {
  const badgeProps = useMemo(() => {
    if (isEditing) {
      return {
        color: 'gold',
        text: <IntlMessages id="ui-general-editing" />,
      };
    }

    if (dayjs.utc(date).isBefore(dayjs.utc().add(12, 'hour'))) {
      return {
        color: 'lime',
        text: <IntlMessages id="ui-general-published" />,
      };
    }

    return {
      color: appColors.gray,
      text: <IntlMessages id="ui-general-publish" />,
    };
  }, [ date, isEditing ]);

  return (
    <Badge.Ribbon text={badgeProps.text} color={badgeProps.color}>
      {children}
    </Badge.Ribbon>
  );
};

PlanningBoardDetailsBadge.propTypes = {
  date: PropTypes.string,
  children: PropTypes.node,
  isEditing: PropTypes.bool,
};

export default PlanningBoardDetailsBadge;
