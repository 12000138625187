import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import isEmpty from 'lodash/isEmpty';
import { logOut, refreshUserData } from '../../domains/Auth/features/authSlice';
import Config from '../../configs/appConfig';
import getTestModeParams from '../../lib/getTestModeParams';
import { makeNotification, notificationTypes } from '../../lib/makeNotification';



const baseQuery = fetchBaseQuery({
  baseUrl: `${Config.VITE_HC_API_URL}`,
  prepareHeaders: async (headers, { getState }) => {
    const { token } = getState().auth;
    const testersModeParams = getTestModeParams();

    headers.set('Sec-Fetch-Site', 'same-origin');
    headers.set('Accept', 'application/json');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (!isEmpty(testersModeParams)) {
      const { is_developer, developer_id } = testersModeParams;

      headers.set('x-api-developer', is_developer);
      headers.set('x-api-developer-id', developer_id);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  const isUserExpired = Boolean(result.meta.response.headers.get('user-expired'));
  const err = result?.error;
  const errorStatusCode = err?.status;

  if (isUserExpired) {
    const refreshResult = await baseQuery('/v1/profile?include=roles,permissions', api, extraOptions);

    api.dispatch(refreshUserData(refreshResult.data));
  }

  if (errorStatusCode === 401) {
    api.dispatch(logOut());
  }
  if (errorStatusCode >= 400 && errorStatusCode < 500) {
    makeNotification(notificationTypes.error, err?.data?.message);
  }
  if (errorStatusCode >= 500) {
    makeNotification(notificationTypes.error, 'Server error! Contact the web development team.');
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  tagTypes: [
    'Profile',
    'Roles',
    'Role',
    'Permissions',
    'Users',
    'User',
    'Languages',
    'Structures',
    'StructureVersions',
    'Dictionaries',
    'Dictionary',
    'DictionaryFilter',
    'Translations',
    'PushIcons',
    'Images',
    'ImagesItem',
    'Categories',
    'CategoriesPriorities',
    'Achievements',
    'Achievement',
    'Tags',
    'Deeplinks',
    'Packs',
    'Bonuses',
    'News',
    'NewsPriority',
    'DynamicNews',
    'DynamicNewsItem',
    'DynamicNewsPriority',
    'Templates',
    'Template',
    'Features',
    'FeaturesItem',
    'FeaturesPriority',
    'Presets',
    'Preset',
    'Popups',
    'PopupsItem',
    'PopupsPriority',
    'Bonuses',
    'BonusesItem',
    'Logs',
    'LogsItem',
    'Collections',
    'CollectionsItem',
    'CollectionsPriority',
    'Feeds',
    'FeedsPlanning',
    'FeedsPlanningDetails',
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (_builder) => ({}),
});
