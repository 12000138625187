import React from 'react';
import { MODAL_MODE } from '../domains/Content/pages/Feeds/PageFeeds.const';
import IntlMessages from '../layout/components/lang/IntlMessages';



const getModalTitleByModalMode = (modalMode) => {
  switch (modalMode) {
    case MODAL_MODE.VIEW:
      return <IntlMessages id="content-image-preview-card-title" />;
    case MODAL_MODE.REMOVE:
      return <IntlMessages id="ui-general-remove" />;
    case MODAL_MODE.UNPUBLISH:
      return <IntlMessages id="ui-general-unpublish" />;
    case MODAL_MODE.EDIT:
      return <IntlMessages id="ui-general-edit" />;
    default:
      return <IntlMessages id="content-image-preview-card-title" />;
  }
};

export default getModalTitleByModalMode;
