import dayjs from 'dayjs';
import { Tag } from 'antd';
import React from 'react';
import { DATE_FORMAT } from '../../../../../const/system';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';



export const getCardBackground = (imagesCount, cellDate) => {
  const minImagesCount = 15;
  const currentDate = dayjs(new Date()).format(DATE_FORMAT);
  const daysDifference = dayjs(cellDate).diff(currentDate, 'days');

  if (imagesCount >= minImagesCount) {
    return 'feed-board-cell-success';
  }
  if (daysDifference > 13) {
    return 'feed-board-cell-warning';
  }
  return 'feed-board-cell-danger';
};

export const showCurrentDay = (calendarDay) => {
  if (dayjs(new Date(calendarDay)).startOf('day').isSame(dayjs(new Date()).startOf('day'))) {
    return (
      <Tag className="feed-calendar-today">
        <IntlMessages id="feeds-calendar-today-cell" />
      </Tag>
    );
  }
  return null;
};
