import isString from 'lodash/isString';



//Clear first empty character and split by search
export const getPathnameWithoutQuery = (value) => {
  if (!value) return '';
  const cleanPath = Array.isArray(value)
    ? value.find((item) => item && item !== '') || ''
    : isString(value) ? value : '';

  const normalizedPath = cleanPath.startsWith('/') ? cleanPath.slice(1) : cleanPath;

  return normalizedPath.split('?')[0].replace(/\/$/, '');
};

//Find best match score for menu item
const getMatchScore = (currentPath, navLink) => {
  const currentPathArr = currentPath.split('/');
  const navLinkArr = navLink.split('/');

  let matchScore = 0;

  // eslint-disable-next-line fp/no-loops
  for (let i = 0; i < Math.min(currentPathArr.length, navLinkArr.length); i++) {
    if (currentPathArr[i] === navLinkArr[i]) {
      matchScore += 1;
    }
  }
  return matchScore;
};

//Recursive search of default opened menu keys
export const findOpenedKeys = (items, selectedKey) => {
  let result = [];

  const findParents = (items, key, parents = []) => {
    items.forEach((item) => {
      if (item.id === key) {
        result = parents;
      }
      if (item.children) {
        findParents(item.children, key, [ ...parents, item.id ]);
      }
    });
  };

  findParents(items, selectedKey);
  return result;
};

//Recursive search of default selected menu keys
export const findSelectedKeys = (currentLocation, items) => {
  let result = { key: null, matchScore: 0 };

  const searchElement = (items) => {
    items.forEach((item) => {
      if (item.children) {
        searchElement(item.children);
      } else {
        const matchScore = getMatchScore(currentLocation, getPathnameWithoutQuery(item.navLink));

        if (matchScore > result.matchScore) {
          result = { key: item.id, matchScore };
        }
      }
    });
  };

  searchElement(items);
  return result.key;
};
