import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import ImageModalCardHeader from '../ImageModalCardHeader';



const ImageModalCardBodyRemove = ({ data, removeText }) => {
  return (
    <>
      <ImageModalCardHeader data={data} />
      <Row gutter={[ 32, 32 ]} justify="left" className='hp-my-24'>
        <Col xs={24} md={12}>
          <img alt="preview" src={data?.preview?.data?.path} />
        </Col>
        <Col xs={24} md={12}>
          {removeText}
        </Col>
      </Row>
    </>
  );
};

ImageModalCardBodyRemove.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
  }),
  removeText: PropTypes.node.isRequired,
};

export default ImageModalCardBodyRemove;
