import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { validationSchema } from '../../forms/FormEditImage/FormEditImage.const';
import { FEEDS_SOURCE_MAP, MODAL_MODE, UPDATED_IMAGE_STATUSES } from '../../pages/Feeds/PageFeeds.const';
import { imageStatuses } from '../../../../const/imageStatuses';
import {
  MYSTERY_CATEGORY_VALUE,
  NO_LINES_HASHTAG_VALUE,
} from '../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import { TIME_FORMAT } from '../../../../const/system';
import { makeInitialEditImageErrors } from '../../../../lib/makeInitialEditImageErrors';
import handleResponse from '../../../../lib/handleResponse';
import transformErrors from '../../../../lib/transformErrors';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import getInitialImageCategoriesValues from '../../../../lib/getInitialImageCategoriesValues';
import Spinner from '../../../../components/Spinner';
import ImageModalCardBodyView from './ImageModalCardBodyView';
import ImageModalCardBodyUnpublish from './ImageModalCardBodyUnpublish';
import ImageModalCardBodyRemove from './ImageModalCardBodyRemove';
import ImageModalCardBodyEdit from './ImageModalCardBodyEdit';
import ImageModalCardFooterEdit from './ImageModalCardFooterEdit';
import ImageModalCardFooterUnpublish from './ImageModalCardFooterUnpublish';
import ImageModalCardFooterRemove from './ImageModalCardFooterRemove';
import ImageModalCardFooterView from './ImageModalCardFooterView';
import { usePlanningBoardImagesContext } from '../../contexts/PlanningImagesContext';
import { useUpdateImageMutation, useUpdateImageStatusMutation } from '../../api/imagesApiSlice';
import { useGetCategoriesQuery } from '../../api/categoriesApiSlice';
import { useGetTagsQuery } from '../../api/tagsApiSlice';



const ImageModalCard = ({
  data,
  handleModalClose,
  handleUpdateListItem = (_id, _values = {}) => {},
  handleModalMode = (_mode) => {},
  modalMode = MODAL_MODE.VIEW,
  editDisabled = false,
  canUpdatePublicationTime = false,
  initialEditImageErrors,
  isImageAddingToBoard = false,
  handleUpdateTotal = () => {},
}) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const feedSource = getSourceFromUrl(pathname);
  const [ imageEditErrors, setImageEditErrors ] = useState({});

  const { updateCurrentlyAddedToBoardItem, items, originalItems, updateItems, updateOriginalItems, updateImageWithErrorsIds, imageWithErrorsIds } = usePlanningBoardImagesContext();
  const [ updateImageStatus, { isLoading: isImageStatusUpdating } ] = useUpdateImageStatusMutation();
  const [ updateImage, { isLoading: isImageUpdating } ] = useUpdateImageMutation();

  const { data: {
    data: categories = [],
  } = { data: [], pagination: {} }, isFetching: isCategoriesFetching } = useGetCategoriesQuery({ queryParams: 'limit=0' });

  const { data: {
    data: hashtags = [],
  } = { data: [], pagination: {} }, isFetching: isHashtagsFetching } = useGetTagsQuery({ queryParams: 'limit=0' });

  const noLinesHashtagId = hashtags?.find((hashtag) => hashtag.tag === NO_LINES_HASHTAG_VALUE)?.id;
  const mysteryCategoryId = categories?.find((category) => category.name === MYSTERY_CATEGORY_VALUE)?.id;

  useEffect(() => {
    setImageEditErrors({});
  }, [ data ]);

  useEffect(() => {
    if (isEmpty(initialEditImageErrors) && !isImageAddingToBoard && noLinesHashtagId && mysteryCategoryId) {
      const imageValidationSchema = validationSchema(
        data,
        noLinesHashtagId,
        mysteryCategoryId,
        isImageAddingToBoard,
      );

      imageValidationSchema.validate({
        comment: data?.comment,
        labels: data?.labels,
        hashtags: data?.tags?.data?.map((tag) => tag.id),
        ...getInitialImageCategoriesValues(data),
      }, { abortEarly: false })
        .catch((error) => makeInitialEditImageErrors(error, setImageEditErrors));
    } else {
      setImageEditErrors(initialEditImageErrors);
    }
  }, [ initialEditImageErrors, noLinesHashtagId, mysteryCategoryId ]);

  const handleUpdateImageStatus = (status) => {
    updateImageStatus({ id: data.id, status })
      .unwrap()
      .then(() => handleModalClose())
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => handleUpdateListItem(data.id),
          status === UPDATED_IMAGE_STATUSES.DRAFT ? () => handleUpdateTotal() : () => {},
        ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  const updateItemsWithNewImage = (items, updatedImage) => {
    const imagesCopy = [ ...items ];

    return imagesCopy.map((item) => {
      if (item.id === updatedImage.id) {
        return updatedImage;
      }

      return item;
    });
  };

  const handleUpdateImage = (values) => {
    updateImage({ id: data.id, payload: values })
      .unwrap()
      .then((response) => {
        handleModalClose();

        return response?.data || {};
      })
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          // Update all feeds pages with new image info without reloading whole page
          () => handleUpdateListItem(data.id, response),
          // Set new adding to board image
          () => {
            if (isImageAddingToBoard) {
              updateCurrentlyAddedToBoardItem(response);
            }
          },
          // Update planning board list with new image info without reloading whole list
          () => {
            if (items) {
              updateItems(updateItemsWithNewImage(items, response));
            }
          },
          // Update planning board original list with new image info without reloading whole list
          () => {
            if (originalItems) {
              updateOriginalItems(updateItemsWithNewImage(originalItems, response));
            }
          },
          // Remove updated image from error indexes list
          () => {
            if (items && imageWithErrorsIds) {
              const newIndexes = [ ...imageWithErrorsIds ].filter((index) => {
                return index !== response.id;
              });

              updateImageWithErrorsIds(newIndexes);
            }
          },
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [ () => setImageEditErrors(transformErrors(error?.data?.errors)) ],
        error,
      ));
  };

  const getModalBodyByModalMode = () => {
    switch (modalMode) {
      case MODAL_MODE.VIEW:
        return (
          <ImageModalCardBodyView data={data} />
        );
      case MODAL_MODE.EDIT:
        return (
          <ImageModalCardBodyEdit
            data={data}
            categories={categories}
            hashtags={hashtags}
            canUpdatePublicationTime={canUpdatePublicationTime}
            isImageAddingToBoard={isImageAddingToBoard}
          />
        );
      case MODAL_MODE.UNPUBLISH:
        return (
          <ImageModalCardBodyUnpublish
            data={data}
            preview={data?.preview?.data?.path}
          />
        );
      case MODAL_MODE.REMOVE:
        return (
          <ImageModalCardBodyRemove
            data={data}
            removeText={feedSource === FEEDS_SOURCE_MAP.TRASHED ?
              <IntlMessages id='feeds-trash-modal-remove-text' /> :
              <IntlMessages id='feeds-archive-modal-trash-text' />}
          />
        );
      default:
        return (
          <ImageModalCardBodyView data={data} />
        );
    }
  };

  const getModalFooterByModalMode = useCallback(() => {
    if (modalMode === MODAL_MODE.EDIT && HasRights([ Permissions.CONTENT.FEEDS.IMAGE.UPDATE ])) {
      return (
        <ImageModalCardFooterEdit
          isLoading={isImageUpdating}
          isImageAddingToBoard={isImageAddingToBoard}
          isSaveAndPublish={[
            imageStatuses.release_on,
            imageStatuses.bonus,
            imageStatuses.achievement,
            imageStatuses.daily,
            imageStatuses.pack,
          ].includes(data.status)}
        />
      );
    }

    if (modalMode === MODAL_MODE.UNPUBLISH) {
      return (
        <ImageModalCardFooterUnpublish
          handleUpdateImageStatus={handleUpdateImageStatus}
          releaseDate={data.release_date}
        />
      );
    }

    if (modalMode === MODAL_MODE.REMOVE) {
      return (
        <ImageModalCardFooterRemove
          handleUpdateImageStatus={handleUpdateImageStatus}
          isLoading={isImageStatusUpdating}
          feedSource={feedSource}
          handleModalClose={handleModalClose}
        />
      );
    }

    if (modalMode === MODAL_MODE.VIEW) {
      return (
        <ImageModalCardFooterView
          data={data}
          handleModalMode={handleModalMode}
          handleUpdateImageStatus={handleUpdateImageStatus}
          feedSource={feedSource}
          editDisabled={editDisabled}
        />
      );
    }

    return undefined;
  }, [ modalMode, data, feedSource ]);

  const isLoading = isCategoriesFetching || isHashtagsFetching || isImageStatusUpdating || isImageUpdating;

  const isRightAlignedModal = feedSource === FEEDS_SOURCE_MAP.TRASHED
    || [ MODAL_MODE.EDIT, MODAL_MODE.REMOVE ].includes(modalMode)
    || ([ FEEDS_SOURCE_MAP.RELEASED ].includes(feedSource) && data.source_type !== 'feed' && modalMode === MODAL_MODE.VIEW)
    || !editDisabled;

  return (
    <Spinner spinning={isLoading}>
      <Formik
        initialValues={{
          comment: data?.comment,
          labels: data?.labels,
          hashtags: data?.tags?.data?.map((tag) => tag.id),
          ...getInitialImageCategoriesValues(data),
          publication_time: canUpdatePublicationTime ? moment(data?.release_date) : null,
        }}
        initialErrors={imageEditErrors}
        validationSchema={validationSchema(data, noLinesHashtagId, mysteryCategoryId, isImageAddingToBoard)}
        enableReinitialize
        isSubmitting={isImageUpdating}
        onSubmit={async (values) => {
          await handleUpdateImage({
            comment: !isEmpty(values?.comment) ? values.comment.trim() : '',
            labels: values.labels,
            hashtags: values.hashtags,
            main_category: values.main_category,
            categories: values.categories,
            publication_time: canUpdatePublicationTime ? moment(values.publication_time, TIME_FORMAT).format(TIME_FORMAT) : null,
          });
        }}
      >
        {() => {
          return (
            <>
              {getModalBodyByModalMode()}
              <Row
                justify={isRightAlignedModal ? 'end' : 'space-between'}
                className='hp-pt-16 hp-border-top-1'
              >
                {getModalFooterByModalMode()}
              </Row>
            </>
          );
        }}
      </Formik>
    </Spinner>
  );
};

ImageModalCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
    release_date: PropTypes.string,
    source_type: PropTypes.string,
    comment: PropTypes.string,
    labels: PropTypes.array,
    tags: PropTypes.object,
  }),
  modalMode: PropTypes.string,
  handleModalClose: PropTypes.func.isRequired,
  handleUpdateListItem: PropTypes.func,
  handleModalMode: PropTypes.func,
  editDisabled: PropTypes.bool,
  canUpdatePublicationTime: PropTypes.bool,
  isImageAddingToBoard: PropTypes.bool,
  initialEditImageErrors: PropTypes.object.isRequired,
  handleUpdateTotal: PropTypes.func,
};

export default ImageModalCard;
