import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import isObject from 'lodash/isObject';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPagePresets from '../../../../../urls/urlPagePresets';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormCreateEditPresets from '../../../forms/FormCreateEditPresets';
import transformErrors from '../../../../../lib/transformErrors';
import handleResponse from '../../../../../lib/handleResponse';
import { useCreatePresetMutation } from '../../../api/presetsApiSlice';



const PagePresetsCreate = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ errors, setFormErrors ] = useState({});

  const [ createPreset, { isLoading: isCreatingPreset } ] = useCreatePresetMutation();

  const onSubmit = (values) => {
    createPreset({ values })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-created-successfully',
        [
          () => navigate(urlPagePresets()),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-create-failed',
        [ () => {
          if (isObject(error?.data?.errors)) {
            setFormErrors(transformErrors(error?.data?.errors));
          }
        } ],
        error,
      ));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPagePresets()}>
              <IntlMessages id='presets-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-create' />}
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormCreateEditPresets
              initialErrors={errors}
              isSubmitting={isCreatingPreset}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePresetsCreate;
