import dayjs from 'dayjs';



const isNotReleased = (releaseDate) => {
  const unpublishDeadlineTime = dayjs().utc().add(12, 'hour');
  // By default, releaseDate is returned from the server in GMT (UTC-0) time.
  // For comparing current time and release time from the server we need to manually set utc(true) for release time
  // to tell dayjs not to convert time to GMT time

  return unpublishDeadlineTime.isBefore(dayjs(releaseDate).utc(true));
};

export default isNotReleased;

