import isEmpty from 'lodash/isEmpty';



export const makeInitialEditImageErrors = (errors, setImageEditErrors) => {
  const initialErrors = {};

  if (!isEmpty(errors.inner)) {
    errors.inner.forEach((err) => {
      if (err.path && !initialErrors[err.path]) {
        initialErrors[err.path] = err.message;
      }
    });
  } else if (errors.path) {
    initialErrors[errors.path] = errors.message;
  }

  if (!isEmpty(initialErrors)) {
    setImageEditErrors(initialErrors);
  }
};
