import React, { useState } from 'react';
import { Card, Col, Modal, Row } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { RiArrowLeftLine, RiCloseFill } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageAchievements from '../../../../../urls/urlPageAchievements';
import urlPageAchievementsEdit from '../../../../../urls/urlPageAchievementsEdit';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import Spinner from '../../../../../components/Spinner';
import ContentCard from '../../../components/ContentCard';
import ActionButton from '../../../../../layout/components/action-button';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import AchievementDetailsCard from '../../../components/AchievementDetailsCard';
import useToggle from '../../../../../app/hooks/useToggle';
import ImageModalCardBodyView from '../../../components/ImageModalCard/ImageModalCardBodyView';
import { useGetAchievementQuery } from '../../../api/achievementsApiSlice';



const PageAchievementDetails = () => {
  const { id } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});

  const {
    data: { data: achievement } = { data: {} },
    isFetching: isAchievementFetching,
  } = useGetAchievementQuery(id);

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageAchievements()}>
              <IntlMessages id='achievements-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={achievement?.name}
        />
        <Col>
          <ActionButton
            icon={<EditOutlined className="hp-mr-4" />}
            onClick={() => {
              navigate(urlPageAchievementsEdit({ id }));
            }}
            title={<IntlMessages id='ui-general-edit' />}
          />
          <ActionButton
            icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
            onClick={() => {
              navigate(urlPageAchievements());
            }}
            title={<IntlMessages id='ui-general-back-to-list' />}
          />
        </Col>
      </Row>

      <Modal
        width={1200}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <ImageModalCardBodyView
          isLoading={isAchievementFetching}
          data={previewImage}
        />
      </Modal>

      <Spinner spinning={isAchievementFetching}>
        <Row gutter={[ 32, 32 ]} className="hp-mb-32">
          <Col span={24}>
            <AchievementDetailsCard data={achievement} />
          </Col>
        </Row>

        <Row gutter={[ 32, 32 ]}>
          <Col xs={24} lg={24}>
            <Card title={<IntlMessages id="achievements-attached-images" />}>
              <div className='content-card-grid'>
                {achievement?.images?.data?.length > 0 ?
                  achievement?.images?.data?.map((item) => {
                    return (
                      <ContentCard
                        key={item.id}
                        data={item}
                        onClick={handleCardClick}
                      />
                    );
                  }) : <EmptyDataPlaceholder placeholder={<IntlMessages id="achievements-no-attached-images" />} />}
              </div>
            </Card>
          </Col>
        </Row>
      </Spinner>
    </>
  );
};

export default PageAchievementDetails;
