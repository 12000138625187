import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PiCalendarBlankBold } from 'react-icons/pi';
import { Button, Card, Col, DatePicker, Modal, Popconfirm, Row, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { DATE_FORMAT } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageFeedsPlanningDetails from '../../../../urls/urlPageFeedsPlanningDetails';
import { Permissions } from '../../../../const/permissions';
import appColors from '../../../../const/colors';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import { makePayload } from './FeedsCalendarWidget.const';
import { usePlanningBoardImagesContext } from '../../contexts/PlanningImagesContext';
import handleResponse from '../../../../lib/handleResponse';
import useToggle from '../../../../app/hooks/useToggle';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import ActionButton from '../../../../layout/components/action-button';
import {
  usePublishFeedsPlanningBoardMutation,
  useUnpublishFeedsPlanningBoardMutation,
} from '../../api/feedsPlanningApiSlice';



const FeedsCalendarWidget = ({
  isEditing,
  isLoading,
  showContour,
  toggleShowContour,
  initialPlanningBoardDetails,
  handleChangeCalendarDate = (_date) => {},
  handleCancelChanges = () => {},
  handleCloseEdit = () => {},
  handleClearFeedsFilter = () => {},
}) => {
  const { date } = useParams();
  const { pathname } = useLocation();
  const feedSource = getSourceFromUrl(pathname);
  const navigate = useNavigate();
  const [ showDatePicker, toggleShowDatePicker ] = useToggle();
  const intl = useIntl();
  const [ isConfirmationModalOpen, setIsConfirmationModalOpen ] = useState(false);
  const [ hasInitialPriority, setHasInitialPriority ] = useState(true);
  const [ selectedDate, setSelectedDate ] = useState(null);
  const { items: planningBoardImages, originalItems: originalPlanningBoardImages, updateImageWithErrorsIds, updateCurrentlyAddedToBoardItem, imageWithErrorsIds } = usePlanningBoardImagesContext();

  const unpublishDeadlineTime = dayjs.utc().add(12, 'hour');
  const selectedDateTime = dayjs.utc(date);
  const canClearPlanningBoard = unpublishDeadlineTime.isBefore(selectedDateTime) && !isEmpty(initialPlanningBoardDetails?.images);
  const showClearPlanningBoardTooltip = unpublishDeadlineTime.isSameOrAfter(selectedDateTime) && !isEmpty(initialPlanningBoardDetails?.images);

  const [ clearPlanningBoard, { isLoading: isClearingPlanningBoard } ] = useUnpublishFeedsPlanningBoardMutation();
  const [ publishPlanningBoard, { isLoading: isPublishingPlanningBoard } ] = usePublishFeedsPlanningBoardMutation();

  const handleClearConfirm = () => {
    clearPlanningBoard({ date: dayjs(date).format(DATE_FORMAT) })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [ () => handleCancelChanges(true) ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  const makeErrorIndexes = (errors) => {
    const imageWithErrorsIds = [];

    Object.keys(errors).forEach((key) => {
      const [ _first, errorIndex ] = key.split('.');

      imageWithErrorsIds.push(planningBoardImages[Number(errorIndex)]?.id);
    });

    updateImageWithErrorsIds(imageWithErrorsIds);
  };

  const handlePublish = () => {
    publishPlanningBoard({ date: dayjs(date).format(DATE_FORMAT), payload: { items: makePayload(planningBoardImages) } })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => navigate(urlPageFeedsPlanningDetails({ feedSource, date })),
          () => updateImageWithErrorsIds([]),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [ () => makeErrorIndexes(error?.data?.errors) ],
        error,
      ));
  };

  useEffect(() => {
    if (!isEmpty(difference(originalPlanningBoardImages, planningBoardImages))) {
      setHasInitialPriority(false);
    } else {
      const isOrderSame = isEqual(originalPlanningBoardImages, planningBoardImages);

      setHasInitialPriority(isOrderSame);
    }
  }, [ originalPlanningBoardImages, planningBoardImages ]);

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleLeaveEditing = () => {
    updateCurrentlyAddedToBoardItem({});
    handleCloseConfirmationModal();
    handleChangeCalendarDate(selectedDate);
  };

  const showModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const onDatepickerChange = (date) => {
    if (isEditing && !isEqual(originalPlanningBoardImages, planningBoardImages)) {
      setSelectedDate(date);
      showModal();
    } else {
      handleChangeCalendarDate(date);
    }
  };

  return (
    <div className='hp-mb-32'>
      <Modal
        title={<IntlMessages id="ui-general-leave-changes-confirm-modal-title" />}
        centered
        visible={isConfirmationModalOpen}
        onOk={handleLeaveEditing}
        onCancel={handleCloseConfirmationModal}
      >
        <IntlMessages id="ui-general-leave-changes-confirm-message" />
      </Modal>

      <Spin spinning={isLoading || isClearingPlanningBoard || isPublishingPlanningBoard} tip=''>
        <Card className="feeds-calendar-widget">
          <Row justify='space-between' align='middle' gutter={[ 8, 8 ]}>
            <Col xs={24} sm={8} align='left'>
              <div className='flex-center'>
                <span className='feeds-calendar-widget__date'>
                  {dayjs(date).format('D MMMM, YYYY')}
                </span>
                <PiCalendarBlankBold
                  className='hp-cursor-pointer feeds-calendar-widget__calendar-icon'
                  size={21}
                  color={appColors.blue}
                  onClick={toggleShowDatePicker}
                />
              </div>

              <DatePicker
                open={showDatePicker}
                format={DATE_FORMAT}
                value={moment(date)}
                className="calendar-widget-datepicker"
                onChange={onDatepickerChange}
                onOpenChange={(open) => {
                  if (!open && showDatePicker) {
                    toggleShowDatePicker();
                  }
                }}
              />
            </Col>

            <div>
              <ActionButton
                className='hp-mr-sm-8 hp-mr-16 hp-px-24'
                size="small"
                onClick={toggleShowContour}
                title={<IntlMessages id={showContour ? 'content-images-show-colors' : 'content-images-show-contour'} />}
              />

              {isEditing && (
                <PrivateComponent
                  isMultipleAllowance
                  allowedPermissions={[
                    Permissions.CONTENT.FEEDS.PLANNING.BOARD.DETAILS,
                    Permissions.CONTENT.FEEDS.PLANNING.BOARD.UNPUBLISH,
                  ]}
                >
                  <Popconfirm
                    title={<IntlMessages id="planning-board-clear-confirm-message" />}
                    placement="top"
                    disabled={!canClearPlanningBoard}
                    onConfirm={handleClearConfirm}
                    okText={<IntlMessages id="ui-general-yes" />}
                    cancelText={<IntlMessages id="ui-general-no" />}
                    icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                    okButtonProps={{ danger: true }}
                  >
                    <Tooltip title={showClearPlanningBoardTooltip ? <IntlMessages id="planning-board-disabled-clear-hint" /> : ''}>
                      <Button
                        size="small"
                        danger
                        className={`hp-mr-16 ${canClearPlanningBoard ? 'feed-modal__button--danger hp-px-24' : 'btn-clear'}`}
                        disabled={!canClearPlanningBoard}
                      >
                        <IntlMessages id="ui-general-clear-all" />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </PrivateComponent>
              )}

              <PrivateComponent
                allowedPermissions={[
                  Permissions.CONTENT.FEEDS.PLANNING.BOARD.UNPUBLISH,
                  Permissions.CONTENT.FEEDS.PLANNING.BOARD.PUBLISH,
                ]}
              >
                {isEditing ? (
                  <>
                    <Button
                      size="small"
                      className='warning-btn hp-px-24'
                      onClick={() => {
                        if (hasInitialPriority) {
                          handleCloseEdit();
                        } else {
                          handleCancelChanges();
                        }
                      }}
                    >
                      <IntlMessages id={hasInitialPriority ? 'ui-general-back-to-view' : 'ui-general-reset'} />
                    </Button>

                    <Tooltip title={!isEmpty(imageWithErrorsIds) ? <IntlMessages id="planning-board-has-errors" /> : ''}>
                      <Button
                        disabled={hasInitialPriority || !isEmpty(imageWithErrorsIds)}
                        className={`hp-ml-16 feeds-calendar-widget-btn-primary ${isEmpty(imageWithErrorsIds) && !hasInitialPriority ? 'hp-px-24' : ''}`}
                        type='primary'
                        size='small'
                        onClick={handlePublish}
                      >
                        <IntlMessages id='ui-general-save-and-publish' />
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  <Button
                    size="small"
                    className='warning-btn hp-px-24'
                    onClick={handleClearFeedsFilter}
                  >
                    <IntlMessages id="ui-general-edit" />
                  </Button>
                )}
              </PrivateComponent>
            </div>
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

FeedsCalendarWidget.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showContour: PropTypes.bool.isRequired,
  toggleShowContour: PropTypes.func.isRequired,
  initialPlanningBoardDetails: PropTypes.object.isRequired,
  handleChangeCalendarDate: PropTypes.func,
  handleCancelChanges: PropTypes.func,
  handleClearFeedsFilter: PropTypes.func,
  handleCloseEdit: PropTypes.func,
};

export default FeedsCalendarWidget;
