const feeds = {
  'feeds-breadcrumbs-list': 'Лента',
  'feeds-breadcrumbs-planning': 'Планирование',
  'feeds-breadcrumbs-released-list': 'Опубликовано',
  'feeds-breadcrumbs-archived-list': 'Архив',
  'feeds-breadcrumbs-trash-list': 'Корзина',
  'feeds-form-very-simple-difficulty': 'Очень просто',
  'feeds-form-simple-difficulty': 'Просто',
  'feeds-form-medium-difficulty': 'Средне',
  'feeds-form-hard-difficulty': 'Сложно',
  'feeds-form-difficulty-tooltip': 'Данные опции автозаполяют мин/макс значения тапов',
  'feeds-list-unpublish-disabled-tooltip': 'Снять с публикации запрещено. Изображение прикреплено к коллекциям и/или к сущности выбор редакторов',
  'feeds-trash-modal-remove-text': 'Внимание! Картинка будет полностью удалена, ее нельзя будет найти в панели и использовать.',
  'feeds-archive-modal-trash-text': 'Иображение будет отправлено в корзину, вы уверены, что хотите выполнить действие?',
  'feeds-trash-modal-return-to-draft-confirm': 'Вы уверены, что хотите перевести изображение обратно в черновики?',
  'feeds-unpublish-modal-trash-confirm': 'Вы уверены, что хотите переместить изображение в корзину?',
  'feeds-unpublish-modal-archive-confirm': 'Вы уверены, что хотите архивировать изображение?',
  'feeds-modal-unpublish-archive-text': 'Если нажать на кнопку Архивировать, то картинка будет снята с публикации, но останется доступной для синхронизации.',
  'feeds-modal-unpublish-trash-text': 'Если нажать на кнопку В корзину, то картинка будет полностью снята с публикации и может быть удалена.',
  'feeds-modal-unpublish-image-in-prod': 'Изображение находится в производстве для всего мира или его части.',
  'feeds-modal-edit-image-save-and-publish-info': 'Мы сообщаем вам о доступности обновлений для мобильного приложения Happy Color. Чтобы успешно опубликовать эти изменения, нажмите на кнопку \'Сохранить и опубликовать\'.',
  'feeds-modal-edit-image-save-info': 'Мы сообщаем вам о доступности обновлений для мобильного приложения Happy Color. Чтобы успешно опубликовать эти изменения, нажмите на кнопку \'Сохранить\'.',
  'feeds-calendar-today-cell': 'Сегодня',
  'feeds-planning-board': 'Доска',
  'feeds-calendar-filter-info': 'По умолчанию диапазон фильтрации установлен на ближайшие 2 недели (т.е. сегодня + 13 дней).',
  'feeds-image-modal-publication-time-info': 'Локальное время игрока',
  'feeds-calendar-median': 'Медиана',
  'feeds-calendar-average': 'Среднее',
  'feeds-card-add-to-board': 'Добавить на доску',
  'planning-board-status-info': 'Информация о цветах:',
  'planning-board-status-info-red': 'Красный - Выбранная дата наступает менее чем через две недели, и для неё не достаточно запланированного контента. Мин: 15 изображений. Пожалуйста, оперативно добавьте недостающий контент.',
  'planning-board-status-info-yellow': 'Желтый - Для выбранной даты не достаточно запланированного контента. Мин: 15 изображений.',
  'planning-board-status-info-green': 'Зеленый - Требование минимального количества запланированного контента (15 изображений) соблюдено для выбранной даты.',
  'planning-board-clear-confirm-message': 'Вы уверены, что хотите очистить доску планирования на эту дату?',
  'planning-board-disabled-clear-hint': 'Нельзя очистить доску планирования на эту дату, есть опубликованные картинки.',
  'planning-board-add-to-board': 'Изменить и добавить на доску',
  'planning-board-save-and-add-to-board': 'Сохранить и добавить на доску',
  'planning-board-unpublish-warning': 'На вашей доске есть несохраненные изменения. После снятия с публикации данного изображения все несохраненные изменения будут потеряны. Вы хотите продолжить?',
  'planning-board-has-errors': 'Пожалуйста, обновите все поломанные изображения (подсвечены красным) прежде чем опубликовать доску.',
};

export default feeds;
