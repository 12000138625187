import React, { useState } from 'react';
import { Col, Row, Card, Table, Input, Space, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { RiAddLine } from 'react-icons/ri';
import { Search } from 'react-iconly';
import { Filter } from 'iconsax-react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import urlPageBonusEdit from '../../../../../urls/urlPageBonusEdit';
import basePagination from '../../../../../const/pagination';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import { BONUSES_SOURCE, getBonusesTableColumns } from './PageBonuses.const';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import mkRandomCode from '../../../utils/mkRandomCode';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import useElementSize from '../../../../../app/hooks/useElementSize';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import handleResponse from '../../../../../lib/handleResponse';
import useToggle from '../../../../../app/hooks/useToggle';
import getConfigurations from '../../../../../lib/getConfigurations';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import DocumentationTooltip from '../../../../../components/DocumentationTooltip/DocumentationTooltip';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Sidebar from '../../../../../components/Sidebar';
import FormBonusesFilter from '../../../forms/FormBonusesFilter';
import FormCreateEditBonus from '../../../forms/FormCreateEditBonus';
import {
  useGetBonusesQuery,
  useCreateBonusMutation,
  useDeleteBonusMutation,
} from '../../../api/bonusesApiSlice';



const { Title } = Typography;

const PageBonuses = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ elementRef, size ] = useElementSize();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ isOpenedCreateBonusSidebar, toggleCreateBonusSidebar ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ initialCodeValue, setInitialRandomCode ] = useState('');
  const [ bonusInitialErrors, setBonusInitialErrors ] = useState({});

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'code', 'is_published', 'title', 'released_at', 'released_to' ];

  const source = BONUSES_SOURCE;
  const bonusesConfig = getConfigurations(source);

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  } = useQueryParams({ searchFields, searchFilterFields });

  const {
    data = { data: [], pagination: {} },
    isFetching,
    error: formErrors = {},
  } = useGetBonusesQuery({
    queryParams: searchParams.toString(),
  });

  const [ deleteBonus, { isLoading: isDeletingBonus } ] = useDeleteBonusMutation();
  const [ createBonus, { isLoading: isCreatingBonus } ] = useCreateBonusMutation();


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setResetManuallyTriggered(false);
  };

  const handleCreate = (values, resetForm) => {
    createBonus({ values })
      .unwrap()
      .then((response) => {
        handleResponse(
          'success',
          intl,
          'ui-general-created-successfully',
          [
            toggleCreateBonusSidebar,
            () => setInitialRandomCode(''),
            resetForm,
            () => navigate(urlPageBonusEdit({ bonusId: response?.data?.id })),
          ],
        );
      })
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          'ui-general-create-failed',
          [ () => setBonusInitialErrors({ fakeField: Date.now(), ...error?.data?.errors } || {}) ],
          error,
        );
      });
  };

  const handleDelete = (bonusId) => {
    deleteBonus(bonusId)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleEdit = (bonusId) => {
    navigate(urlPageBonusEdit({ bonusId }));
  };

  const isLoading = isFetching || isDeletingBonus || isCreatingBonus;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbActive={
            <div>
              <IntlMessages id='bonuses-breadcrumbs' />
              <DocumentationTooltip link="https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/878477340/Bonuses" />
            </div>
          }
        />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.CREATE ]}>
                  <ActionButton
                    onClick={() => {
                      toggleCreateBonusSidebar();
                      setBonusInitialErrors({});
                      setInitialRandomCode(mkRandomCode());
                    }}
                    icon={<RiAddLine className="btn-icon-mr-1" />}
                    title={<IntlMessages id='ui-general-create' />}
                  />
                </PrivateComponent>
                <ActionButton
                  title=""
                  isActive={!isEmpty(initFilterValues)}
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          key={source}
          title={<Title level={5}>{intl.formatMessage({ id: 'bonuses-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormBonusesFilter
            initialValues={initFilterValues}
            formErrors={formErrors}
            isSubmitting={false}
            onCancel={() => {
              setResetManuallyTriggered(true);

              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                resetIsManuallyTriggered,
                setSearchTerm,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField: setSearchTerm,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>

        <Sidebar
          title={<IntlMessages id='bonus-create-sidebar' />}
          visible={isOpenedCreateBonusSidebar}
          toggleSidebar={toggleCreateBonusSidebar}
        >
          <FormCreateEditBonus
            initialValues={{ code: initialCodeValue }}
            initialErrors={bonusInitialErrors}
            onSubmit={handleCreate}
            onCancel={() => {}}
            isSubmitting={isCreatingBonus}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                loading={makeTableLoadingObject(isLoading)}
                rowKey="id"
                columns={
                  getBonusesTableColumns(
                    handleEdit,
                    handleDelete,
                    bonusesConfig,
                    size,
                  )
                }
                dataSource={data.data}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: data.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageBonuses;
