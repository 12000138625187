import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const TemplatesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Templates', 'Template' ],
  endpoints: (builder) => {
    return ({
      getTemplates: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/templates${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Templates' ],
      }),
      getTemplateContentTypes: builder.query({
        query: () => ({ url: `/${Config.VITE_HC_API_VERSION}/templates/types` }),
        transformResponse: (response) => response?.data,
        providesTags: [ 'Templates' ],
      }),
      createTemplate: builder.mutation({
        query: ({ payload }) => {
          return ({
            url: `/${Config.VITE_HC_API_VERSION}/templates`,
            method: 'POST',
            body: payload,
          });
        },
        invalidatesTags: () => [ 'Templates' ],
      }),
      getTemplate: builder.query({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/templates/${id}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          return {
            data: response?.data,
          };
        },
        providesTags: [ 'Template' ],
      }),
      updateTemplate: builder.mutation({
        query: ({ id, payload }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/templates/${id}`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: () => [
          'Templates', 'Template', 'Collections', 'CollectionsItem', 'Popups', 'PopupsItem',
          'Features', 'FeaturesItem', 'DynamicNews', 'DynamicNewsItem', 'Presets', 'Preset',
        ],
      }),
      cloneTemplate: builder.mutation({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/templates/${id}/clones`,
          method: 'POST',
        }),
        invalidatesTags: () => [ 'Templates', 'Template' ],
      }),
      deleteTemplate: builder.mutation({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/templates/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'Templates' ],
      }),
    });
  },
});

export const {
  useGetTemplatesQuery,
  useGetTemplateContentTypesQuery,
  useGetTemplateQuery,
  useCloneTemplateMutation,
  useDeleteTemplateMutation,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
} = TemplatesApiSlice;
