import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Badge, Modal, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import useToggle from '../../../../app/hooks/useToggle';
import { usePlanningBoardImagesContext } from '../../contexts/PlanningImagesContext';
import { getCardFormat, getTapsLimit } from '../../pages/Feeds/PageFeedsPlanningDetails/PageFeedsPlanningDetailsContent.const';
import { hasRights, HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { imageStatuses } from '../../../../const/imageStatuses';
import ButtonDraft from '../../../../components/ButtonDraft';
import ButtonDanger from '../../../../components/ButtonDanger';
import ContentCard from '../ContentCard';



const DraggableContent = forwardRef(({
  className,
  index,
  data,
  style = {},
  onPointerDown = () => {},
  onPointerMove = () => {},
  onPointerUp = () => {},
  onClick = () => {},
  showContour = false,
  actions = [],
  attributes,
  listeners,
}, ref) => {
  const actualIndex = index + 1;
  const hasTooltip = !!(getTapsLimit(actualIndex) || getCardFormat(actualIndex));

  const children = (
    <div
      ref={ref}
      className={className}
      style={style}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...attributes} {...listeners}
    >
      <Badge.Ribbon text={actualIndex} color="geekblue">
        <ContentCard
          data={data}
          onClick={onClick}
          showContour={showContour}
          actions={actions}
          isPlanningBoard
        />
      </Badge.Ribbon>
    </div>
  );

  if (hasTooltip) {
    return (
      <Tooltip
        title={
          <span className="content-image__help-label">
            {getTapsLimit(actualIndex)}
            {getCardFormat(actualIndex)}
          </span>
        }
      >
        {children}
      </Tooltip>
    );
  }

  return children;
});

DraggableContent.displayName = 'DraggableContent';


export const SortableItem = ({
  id,
  index,
  item,
  isEditing = false,
  handleCardClick,
  showContour = false,
  toggleUnpublishModal = (_item) => {},
  returnToStorage,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const [ isDragging, setIsDragging ] = useState(false);
  const [ modalItem, setModalItem ] = useState({});
  const [ isWaringModalOpen, toggleWaringModal ] = useToggle();

  const {
    imageWithErrorsIds,
    items: actualBoardImages,
    originalItems: originalBoardImages,
  } = usePlanningBoardImagesContext();

  const hasUsedData = !isEmpty(item?.used_in_collections) || !isEmpty(item?.used_in_editor_choices);
  const hasActionButton = hasRights([
    Permissions.CONTENT.FEEDS.IMAGE.SET_ARCHIVED,
    Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED,
    Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT,
  ]) || actualBoardImages.some((item) => item.status === imageStatuses.ready_for_release);

  const handleClick = (event, item) => {
    if (event.target && event.target.classList.contains('btn-danger')) {
      event.target?.blur();

      if (!isEqual(originalBoardImages, actualBoardImages)) {
        setModalItem(item);
        toggleWaringModal();
      } else {
        toggleUnpublishModal(item);
      }

      return;
    }

    if (event.target && event.target.classList.contains('btn-draft')) {
      event.target?.blur();
      returnToStorage(item);

      return;
    }

    if (!isDragging) {
      handleCardClick(item);
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    cursor: !isEditing ? 'pointer' : 'grab',
    minHeight: isEditing && hasActionButton ? 390 : 350,
  };

  const actions = (item) => {
    if (!isEditing) {
      return [];
    }

    return [
      <div key='unpublish' style={{ height: isEditing && hasActionButton ? 40 : 0 }}>
        {item.status === imageStatuses.ready_for_release ? (
          <ButtonDraft
            className='btn-draft hp-p-0'
            key='unpublish'
            hasTooltip={hasUsedData}
          />
        ) : HasRights([
          Permissions.CONTENT.FEEDS.IMAGE.SET_ARCHIVED,
          Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED,
          Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT,
        ]) && (
          <ButtonDanger
            disabled={hasUsedData}
            className='hp-p-0 btn-danger'
            key='unpublish'
            hasTooltip={hasUsedData}
          />
        )}
      </div>,
    ];
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="ui-general-leave-changes-confirm-modal-title" />}
        centered
        visible={isWaringModalOpen}
        onOk={() => {
          toggleWaringModal();
          toggleUnpublishModal(modalItem);
        }}
        onCancel={() => {
          setModalItem({});
          toggleWaringModal();
        }}
      >
        <IntlMessages id="planning-board-unpublish-warning" />
      </Modal>

      <DraggableContent
        ref={setNodeRef}
        className={`draggable-content ${imageWithErrorsIds.includes(item?.id) ? 'error-pulsar-border' : ''}`}
        style={style}
        index={index}
        data={item}
        onPointerDown={() => setIsDragging(false)}
        onPointerMove={() => setIsDragging(true)}
        onPointerUp={(event) => handleClick(event, item)}
        onClick={handleCardClick}
        showContour={showContour}
        actions={actions(item)}
        attributes={attributes}
        listeners={listeners}
      />
    </>
  );
};

DraggableContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  onPointerDown: PropTypes.func,
  onPointerMove: PropTypes.func,
  onPointerUp: PropTypes.func,
  onClick: PropTypes.func,
  showContour: PropTypes.bool,
  actions: PropTypes.array,
  attributes: PropTypes.any,
  listeners: PropTypes.any,
};

SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  handleCardClick: PropTypes.func,
  toggleUnpublishModal: PropTypes.func,
  showContour: PropTypes.bool,
  isEditing: PropTypes.bool,
  returnToStorage: PropTypes.func,
  originalBoardImages: PropTypes.array,
};
